const getUrl = (url) => {
  if (url == "") {
    url = "1713420409240.jpg";
  }

  return (
    "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/" +
    url
  );
};

export default getUrl;
