<template>
  <div class="btm" @click="start()">11111111111</div>
  <img :src="src" alt="" />
  <div v-for="(item, index) in list" :key="index">
    <img :src="item" alt="" />
  </div>
  <!-- <diV>{{ list }}</diV> -->
</template>

<script setup>
import JSzip from "jszip";
import JSZipUtils from "jszip-utils";
import { onMounted, reactive, ref } from "vue";
let path =
  "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/netdisk/1728868608017.zip";

let zip = new JSzip();
let data = reactive({
  show: true,
  src01: require("@/assets/e6.webp"),
  url: "",
});
let src = ref("GSIzEqUaUAA1Bbx6.jpg");
let list = ref([]);
onMounted(() => {});

const start = () => {
  JSZipUtils.getBinaryContent("001.zip", function (err, data) {
    if (err) throw err;

    // 解析Zip文件
    zip.loadAsync(data).then(function (zip) {
      // 读取Zip中的文件
      for (let i = 0; i < 4; i++) {
        zip
          .file(i + ".jpg")
          .async("base64")
          .then((res) => {
            let imgBase64 = "data:image/jpeg;base64, " + res;
            list.value.push(imgBase64);
          });
      }
    });
  });
};
</script>

<style lang="less" scoped>
.btn {
  margin: 100px;
}
img {
  width: 300px;
  height: 300px;
  object-fit: contain;
  border: 1px solid red;
}
</style>
