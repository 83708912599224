<template>
  <div class="orderBox">
    <div class="order">
      <div
        class="goods"
        v-for="(item, index) in data.order_goodsinfo"
        :key="index"
      >
        {{ item }}
      </div>
      <div class="item">{{ "订单编号：" + data.orderinfo.para_02 }}</div>
      <div class="item">{{ "下单时间：" + data.orderinfo.para_03 }}</div>
      <div class="item">{{ "总价：" + data.orderinfo.para_11 + "元" }}</div>
      <div class="item">{{ "备注：" + data.orderinfo.para_06 }}</div>
    </div>
  </div>
  <div class="inputBox" ref="myref">
    <div class="searchBox">
      <div class="search">
        <input
          v-model="data.input"
          placeholder="请输入订单编号"
          type="text"
          @keyup.enter="getOrderInfo()"
        />
        <div class="btn" @click="getOrderInfo()">搜索</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import api from "@/api/index";
import axios from "axios";
let data = reactive({
  orderinfo: {},
  order_goodsinfo: [],
});
let myref = ref(null);
onMounted(() => {});
const getOrderInfo = () => {
  let input = data.input.trim();
  if (data.input == "") {
    return alert("请输入订单编号");
  }
  // 获取订单信息
  let data_getOrder = {
    command: "selectByPara1and2",
    para_01: "小程序_订单",
    para_02: input,
  };
  axios({
    method: "get",
    url: api.host + api.selectUniversal,
    params: data_getOrder,
  }).then((res) => {
    if (res.data.results.length == 0) {
      return alert("订单号错误");
    } else {
      myref.value.style.display = "none";
      data.orderinfo = res.data.results[0];
      data.order_goodsinfo = data.orderinfo.para_12.split("  ");
    }
  });
};
</script>

<style lang="less" scoped>
.inputBox {
  display: flex;
  .searchBox {
    width: 100%;
    height: 100%;
    background-color: white;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    .search {
      width: 400px;
      //   border: 1px solid red;
      display: flex;
      height: 35px;
      input {
        border: 2px solid rgb(200, 200, 200);
        padding: 6px 0;
        border-radius: 5px;
        text-align: left;
        width: 80%;
      }
      .btn {
        width: 20%;
        height: 100%;
        background-color: rgb(255, 177, 55);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
      }
      .btn:hover {
        background-color: skyblue;
      }
    }
  }
}
.orderBox {
  background-color: rgb(230, 230, 230);
  width: 100%;
  height: 100%;
  padding: 120px 30%;
  position: fixed;
  top: 0;
  left: 0;
  .order {
    box-shadow: 0 0 2px 2px rgb(200, 200, 200);
    background-color: white;
    height: 100%;
    border-radius: 10px;
    padding: 50px 0 0 80px;
    text-align: left;
    .item {
      margin: 10px 0 0 0;

      text-align: left;
    }
    .goods {
      text-align: left;
    }
  }
}
</style>
