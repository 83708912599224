<template>
  <div class="bottom">
    <div class="box_01 title">联系方式</div>
    <div class="box_01">
      <div class="icon">
        <img :src="imgList.icon[1]" alt="" />
      </div>
      <div class="text">butterfly360</div>
    </div>
    <div class="box_01">
      <div class="icon">
        <img :src="imgList.icon[2]" alt="" />
      </div>
      <div class="text">13623828327 丁先生</div>
    </div>
    <div class="box_01">
      <div class="icon">
        <img :src="imgList.icon[0]" alt="" />
      </div>
      <div class="text">郑州-高新区-莲花街316号-6号楼-207厂房</div>
    </div>
    <img class="code" :src="imgList.icon[3]" alt="" />
    <div class="box_02">
      <div class="title">微信好友</div>
      <div class="title">关注我们</div>
      <div class="title">官方抖音</div>
    </div>
    <div class="box_03">郑州中昆农业技术开发有限公司 豫ICP备12010654号-1</div>
  </div>
</template>

<script>
import imgList from "@/myJs/imgList";
export default {
  name: "ContactInformation",
  setup() {
    return {
      imgList,
    };
  },
};
</script>

<style lang="less" scoped>
.bottom {
  padding: 5vw 2vw;
  margin: 0;
  width: 100%;
  background-color: rgb(51, 51, 51);

  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  .code {
    width: 100vw;
    object-fit: contain;
    padding: 2vw;
  }
  .box_03 {
    padding: 2vh 0 0 0;
    font-size: 0.8rem;
  }
  .box_02 {
    display: flex;
    justify-content: center;
    .title {
      padding: 0 7vw;
    }
  }

  .box_01 {
    width: 100%;
    display: flex;
    justify-content: left;
    padding: 3px 0;

    .icon {
      width: 5vw;
      height: 5vw;
      margin: 0 3vw 0 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .title {
    font-size: 1rem;
  }
}
</style>
