<template>
  <div class="box">
    <div class="search_box">
      <!-- <select
        name=""
        id=""
        v-model="data.select_order"
        @change="getDataByOrder(data.select_order)"
      >
        <option value="鳞翅目">鳞翅目</option>
        <option value="鞘翅目">鞘翅目</option>
        <option value="䗛目">䗛目</option>
        <option value="半翅目">半翅目</option>
        <option value="蜻蜓目">蜻蜓目</option>
        <option value="直翅目">直翅目</option>
      </select> -->
      <select
        name=""
        id=""
        v-model="data.select"
        @change="getDataByFamily(data.select)"
      >
        <option value="全部种类">全部种类</option>
        <!-- <option value="闪蝶科" v-show="data.select_order == '鳞翅目'">
          闪蝶科
        </option> -->
        <option value="凤蝶科" v-show="data.select_order == '鳞翅目'">
          凤蝶科
        </option>
        <option value="蛱蝶科" v-show="data.select_order == '鳞翅目'">
          蛱蝶科
        </option>
        <!-- <option value="蚬蝶科" v-show="data.select_order == '鳞翅目'">
          蚬蝶科
        </option> -->
        <option value="粉蝶科" v-show="data.select_order == '鳞翅目'">
          粉蝶科
        </option>
        <option value="环蝶科" v-show="data.select_order == '鳞翅目'">
          环蝶科
        </option>
        <option value="眼蝶科" v-show="data.select_order == '鳞翅目'">
          眼蝶科
        </option>
        <option value="灰蝶科" v-show="data.select_order == '鳞翅目'">
          灰蝶科
        </option>
        <option value="斑蝶科" v-show="data.select_order == '鳞翅目'">
          斑蝶科
        </option>
        <option value="珍蝶科" v-show="data.select_order == '鳞翅目'">
          珍蝶科
        </option>
        <option value="喙蝶科" v-show="data.select_order == '鳞翅目'">
          喙蝶科
        </option>
        <!-- 鞘翅目 -->
        <option value="花金龟科" v-show="data.select_order == '鞘翅目'">
          花金龟科
        </option>
        <option value="锹甲科" v-show="data.select_order == '鞘翅目'">
          锹甲科
        </option>
        <option value="犀金龟科" v-show="data.select_order == '鞘翅目'">
          犀金龟科
        </option>
        <option value="吉丁总科" v-show="data.select_order == '鞘翅目'">
          吉丁总科
        </option>
        <option value="象甲总科" v-show="data.select_order == '鞘翅目'">
          象甲总科
        </option>
      </select>
      <input type="text" v-model="data.input" />
      <div>{{ filter.length }}</div>
      <div class="select_btn" @click="select">选择完毕</div>
      <div class="select_btn" @click="popWindow('open')">联系我</div>
      <div class="select_btn" @click="ShowPayPopWindow('open')">付款</div>
      <div class="note">{{ data.note }}</div>
    </div>

    <!-- mobile版 -->
    <div class="con_mobile">
      <div class="item_mobile" v-for="(item, index) in filter" :key="index">
        <div class="ite_img">
          <img
            :src="
              'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
              item.para_09
            "
            v-show="item.para_09"
          />
        </div>
        <div class="ite" v-show="data.select_order == '鳞翅目'">
          <div class="ite_01 bold">
            {{ data.bodySize + "：" + item.para_06 + "厘米" }}
          </div>
        </div>
        <div class="ite">
          <div class="ite_01 bold">{{ item.para_04 }}</div>
          <div class="ite_01 latin_mobile">{{ item.para_05 }}</div>
        </div>
        <div class="ite">
          <div class="ite_01">{{ item.para_02 }}</div>
          <div class="ite_01">{{ item.para_03 }}</div>
        </div>
        <div class="ite">{{ item.para_06 }}</div>
        <div class="ite">
          <div class="ite_01">款式：</div>
          <div class="ite_01">{{ item.para_07 }}</div>
        </div>
        <div class="ite">
          <div class="ite_01">价格：</div>
          <div class="ite_01">{{ item.para_08 }}</div>
        </div>
        <div class="ite">
          <div class="ite_01">库存：</div>
          <div class="ite_01">{{ item.para_15 }}</div>
        </div>
        <!-- <div
          class="check"
          @click="check(item.id, item.para_04)"
          v-show="item.para_15 > 0"
        >
          选择
        </div> -->
      </div>
    </div>

    <!-- pc版 -->
    <div class="con">
      <div class="table">
        <table>
          <tr class="tableHead">
            <td class="td_02 pc_only">图片</td>
            <td class="td_03">{{ data.bodySize + "(厘米)" }}</td>
            <td class="td_02" @click="window()">中文名</td>
            <td class="td_02">拉丁名</td>
            <td class="td_03">分类</td>

            <td class="td_01">款式</td>
            <td class="td_01">价格(元/只)</td>
            <td class="td_0_5">库存</td>
            <!-- <td>采购数量</td> -->
            <td class="td_0_5 checkBox">选择框</td>
            <td class="td_0_5" v-show="$store.state.token == '1'">修改</td>
          </tr>
        </table>
        <div class="goodList" v-for="(item, index) in filter" :key="index">
          <table>
            <tr>
              <td class="td_02 pc_only">
                <div class="img_box">
                  <!-- <img :src="getImg(item.para_15)" /> -->
                  <img
                    :src="
                      'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
                      item.para_09
                    "
                    v-show="item.para_09"
                    @click="ShowMainImgPopWindow('open', item.para_10)"
                  />
                </div>
              </td>
              <td class="td_03">{{ item.para_06 }}</td>
              <td class="td_02">{{ item.para_04 }}</td>
              <td class="td_02 latin">{{ item.para_05 }}</td>
              <td class="td_03">{{ item.para_02 + " " + item.para_03 }}</td>

              <td class="td_01">{{ item.para_07 }}</td>
              <!-- 单价 -->
              <td class="td_01">{{ item.para_08 }}</td>
              <td class="td_0_5">{{ item.para_15 }}</td>
              <!-- <td class="td_01"><input type="number" /></td> -->
              <td class="td_0_5 checkBox">
                <!-- <input
                  v-show="item.para_08.trim() != ''"
                  type="checkbox"
                  @click="check(item.id, item.para_04)"
                /> -->
                <div
                  class="check"
                  @click="check(item.id, item.para_04)"
                  v-show="item.para_15 > 0"
                >
                  选择
                </div>
              </td>
              <td
                class="td_0_5 change"
                v-show="$store.state.token == '1'"
                @click="OpenChangeWindow('open', item.id)"
              >
                修改
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="name_list">
      <!-- <div>购物车</div> -->
      <!-- <div>{{ data.selectedName }}</div> -->
      <div class="item" v-for="(item, index) in data.selectedName" :key="index">
        <div>{{ item }}</div>
      </div>
    </div>

    <div class="window">
      <div class="box">
        <table>
          <tr class="head_box">
            <td>种类</td>
            <td>科</td>
            <td>款式</td>
            <td>价格（元/只）</td>
            <td>采购数量</td>
            <td>合计（元）</td>
          </tr>
          <tr v-for="(ite, index) in data.selectResult" :key="index">
            <td>{{ ite[0].para_04 }}</td>
            <td>{{ ite[0].para_03 }}</td>
            <td>{{ ite[0].para_07 }}</td>
            <td>{{ ite[0].para_08 }}</td>
            <td class="td_01">
              <input
                type="number"
                v-model="ite[0].para_09"
                @change="changeNum"
              />
            </td>
            <td>{{ ite[0].para_08 * ite[0].para_09 }}</td>
          </tr>
        </table>
        <!-- 优惠码 -->
        <div class="parce_box">
          <div class="coupon">
            <input
              type="text"
              placeholder="请输入优惠码"
              v-model="data.coupon_code"
            />
            <div class="coupon_btn" @click="GetDiscount()">确定</div>
          </div>
          <div class="coupon">优惠金额(元)： {{ data.discount }}</div>
          <div class="count" @click="count">计算总价</div>
          <div class="allPrice" v-show="data.procurePrice != 0">
            {{ data.procurePrice }}
          </div>
        </div>
        <input
          type="text"
          class="note"
          placeholder="请输入联系方式和其他备注信息"
          v-model="data.note_order"
        />
        <div class="submit_box">
          <div class="submit" @click="cancle">取消</div>
          <div class="submit" @click="submit">提交订单</div>
        </div>
      </div>
    </div>
    <div class="img_box">
      <div class="box"></div>
    </div>
  </div>
  <!-- 弹窗 -->
  <div class="popWindow">
    <div class="shell" @click="popWindow('close')">
      <div class="payBox">
        <img src="@/assets/code_han.jpg" alt="" />
        <div class="note">韩经理</div>
      </div>
      <div class="payBox">
        <img
          :src="
            'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
            data.weixin_img
          "
          alt=""
        />
        <div class="note">扫码进群</div>
        <div class="note">{{ data.weixin_note }}</div>
      </div>
    </div>
  </div>

  <!-- 大图弹窗 -->
  <div class="mainImg_popWindow">
    <div class="box" @click="ShowMainImgPopWindow('close')">
      <img :src="data.mainSrc" alt="" />
    </div>
  </div>

  <!-- 收款码弹窗 -->
  <div class="PaymentCodeWindow">
    <div class="box" @click="ShowPayPopWindow('close')">
      <div class="payBox">
        <img src="@/assets/code_01.jpg" alt="" />
        <div class="note">微信</div>
      </div>
      <div class="payBox">
        <img src="@/assets/code_02.jpg" alt="" />
        <div class="note">支付宝</div>
      </div>
    </div>
  </div>
  <!-- 修改价格、库存窗口 -->
  <div class="popWindow_change">
    <div class="box">
      <div class="inputBox">
        <div class="title">价格</div>
        <input type="text" v-model="data.changePrice" />
      </div>
      <div class="inputBox">
        <div class="title">库存</div>
        <input type="text" v-model="data.changeInventory" />
      </div>
      <div class="inputBox"></div>
      <div class="btnBox">
        <div class="btn" @click="OpenChangeWindow('close')">取消</div>
        <div class="btn" @click="UpdateDataById()">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/index";
import axios from "axios";
import { computed, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    let $route = useRoute();
    let data = reactive({
      data: {},
      type: {},
      select: "",
      inputValue: "",
      style: $route.query.style,
      mainSrc: "", // 大图
      check: "", //选择框
      selectedId: [],
      selectedName: [],
      selectResult: [],
      showWindow: 0,
      resule: {},
      procureNum: [],
      procurePrice: 0, //采购价
      note: "", //订单备注
      input: "",
      select: "全部种类",
      miniSrc: "", //根据图片id得到的小图
      select_order: "鳞翅目", //根据目筛选数据
      note_order: "", //增值服务备注
      weixin_note: "", //微信群
      weixin_img: "",
      coupon_code: "", //优惠码
      discount: 0, //优惠金额
      discount_style: "", //优惠类型
      changeInventory: "", //修改库存
      changePrice: "", //修改价格
      bodySize: "展翅宽度",
    });
    let dataToSql = reactive({
      command: "",
      id: null,
      para_01: "中昆商品",
      para_02: "",
      para_03: "",
      para_04: "",
      para_05: "",
      para_06: "",
      para_07: "",
      para_08: "",
      para_09: "",
      para_10: "",
      para_11: "国内",
      para_12: "",
      para_13: "",
      para_14: "",
      para_15: "",
    });
    onMounted(() => {
      getDataByOrder("鳞翅目");
      // 获取备注
      setTimeout(() => {
        GetNote();
      }, 100);

      // 获取微信群信息
      GetWeixinQinData();
    });

    //打开、关闭修改库存窗口
    const OpenChangeWindow = (isShow, id) => {
      let dom = document.querySelector(".popWindow_change");
      if (isShow == "open") {
        dom.style.display = "block";
        dataToSql.id = id;
        GetDataById(id);
      } else if (isShow == "close") {
        dom.style.display = "none";
      }
    };
    // 根据id获取价格库存信息
    const GetDataById = (id) => {
      let data_02 = {
        command: "selectById",
        id: id,
      };
      axios
        .get(api.host + api.selectUniversal, {
          params: data_02,
        })
        .then((res) => {
          data.changePrice = res.data.results[0].para_08;
          data.changeInventory = res.data.results[0].para_15;
        });
    };
    // 修改价格和库存
    const UpdateDataById = () => {
      let data_01 = {
        command: "update8and15ById",
        id: dataToSql.id,
        para_08: data.changePrice,
        para_15: data.changeInventory,
      };

      axios({
        method: "post",
        url: api.host + api.universal,
        headers: "multipart/form-data",
        data: data_01,
      }).then((res) => {
        // 重新获取数据
        getDataByOrder(data.select_order);
        // 关闭弹窗
        OpenChangeWindow("close");
      });
    };

    // 根据优惠码获取折扣信息
    const GetDiscount = () => {
      let data_coupon = {
        para_01: "优惠券",
        para_02: data.coupon_code,
        command: "selectByPara1and2",
      };

      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: data_coupon,
      }).then((res) => {
        data.discount = res.data.results[0].para_04;
        data.discount_style = res.data.results[0].para_03;
      });
    };

    // 获取微信群信息
    const GetWeixinQinData = () => {
      let data_weixin = {
        para_01: "杂项",
        para_02: "微信群信息",
        command: "selectByPara1and2",
      };

      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: data_weixin,
      }).then((res) => {
        data.weixin_note = res.data.results[0].para_03;
        data.weixin_img = res.data.results[0].para_10;
      });
    };

    // 主图窗口显隐
    const ShowMainImgPopWindow = (isShow, src) => {
      // 关闭付款码窗口
      // ShowPayPopWindow("close");

      let window = document.querySelector(".mainImg_popWindow");
      if (isShow == "open") {
        window.style.display = "block";
        data.mainSrc =
          "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/" +
          src;
      } else if (isShow == "close") {
        window.style.display = "none";
      }
    };
    // 付款码窗口显隐
    const ShowPayPopWindow = (isShow, src) => {
      // 关闭联系方式窗口
      // ShowMainImgPopWindow("close");

      let window = document.querySelector(".PaymentCodeWindow");
      if (isShow == "open") {
        window.style.display = "block";
        data.mainSrc =
          "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/" +
          src;
      } else if (isShow == "close") {
        window.style.display = "none";
      }
    };

    // 获取备注
    const GetNote = () => {
      dataToSql.para_01 = "杂项";
      dataToSql.para_02 = "进口标本报价表备注";
      dataToSql.command = "selectByPara1and2";
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        data.note = res.data.results[0].para_03;
      });
    };

    const getImg = (id) => {
      // dataToSql.id = id;
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: {
          id: id,
          command: "selectByImgId",
        },
      }).then((res) => {
        let miniName = res.data.results[0].miniImage;

        return require("./" + miniName);
      });
    };

    const filter = computed(() => {
      let item = [];
      let input = data.input.trim().toLowerCase();
      for (let k in data.data) {
        if (input == "") {
          item.push(data.data[k]);
        } else {
          if (data.data[k].para_04.includes(input)) {
            item.push(data.data[k]);
          } else if (data.data[k].para_05.toLowerCase().includes(input)) {
            item.push(data.data[k]);
          } else if (data.data[k].para_02.includes(input)) {
            item.push(data.data[k]);
          } else if (data.data[k].para_03.includes(input)) {
            item.push(data.data[k]);
          } else if (data.data[k].para_07.includes(input)) {
            item.push(data.data[k]);
          }
        }
      }

      return item;
    });

    // 下拉框搜索
    const getDataByFamily = (family) => {
      if (data.select_order == "鳞翅目") {
        data.bodySize = "展翅宽度";
      } else {
        data.bodySize = "体长";
      }

      dataToSql.para_01 = "中昆商品";

      dataToSql.para_03 = family;
      if (family == "全部种类") {
        // 获取该目全部种类
        getDataByOrder(data.select_order);
      } else {
        // 获取该科全部种类
        getData("selectBy_01_03_REGEXP_11");
      }
    };
    const getDataByOrder = (order) => {
      dataToSql.para_01 = "中昆商品";
      dataToSql.para_02 = order;

      data.select = "全部种类";
      console.log(dataToSql);
      getData("selectBy_01_02_REGEXP_11");
    };

    // 获取全部数据
    const getData = (command) => {
      if (data.select_order == "鳞翅目") {
        data.bodySize = "展翅宽度";
      } else {
        data.bodySize = "体长";
      }

      dataToSql.command = command;
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        if (
          command == "selectPriseList" ||
          command == "selectBy1and3" ||
          command == "selectBy1and7" ||
          command == "selectByPara1and2" ||
          command == "selectBy_01_03_REGEXP_11" ||
          command == "selectBy_01_02_REGEXP_11"
        ) {
          data.data = res.data.results;
        } else if (command == "selectById") {
          res.data.results[0].para_09 = 0;
          data.selectResult.push(res.data.results);
          // Object.assign(data.selectResult, res.data.results);
        }
      });
    };

    const select = () => {
      if (data.selectedId.length == 0) {
        alert("未选择商品");
        return;
      }
      for (let i = 0; i < data.selectedId.length; i++) {
        dataToSql.id = data.selectedId[i];
        getData("selectById");
      }
      window();
    };

    // 选择商品
    const check = (id, name, style) => {
      if (data.selectedId.includes(id)) {
        data.selectedId = data.selectedId.filter((item) => item !== id);
      } else {
        data.selectedId.push(id);
        // data.selectedName.push(name + " " + style);
      }

      // for循环
      data.selectedName = [];
      for (let i = 0; i < data.selectedId.length; i++) {
        axios({
          method: "get",
          url: api.host + api.selectUniversal,
          params: {
            id: data.selectedId[i],
            command: "selectById",
          },
        }).then((res) => {
          data.selectedName.push(
            res.data.results[0].para_04 +
              "_" +
              res.data.results[0].para_07 +
              "_" +
              res.data.results[0].para_08
          );
        });
      }
    };

    // 订单窗口
    const window = () => {
      data.procurePrice = 0;
      let window = document.querySelector(".window");
      if (data.showWindow == 0) {
        window.style.display = "block";

        data.showWindow = 1;
      } else if (data.showWindow == 1) {
        window.style.display = "none";
        data.showWindow = 0;
      }
    };

    // 计算总价
    const count = () => {
      data.procurePrice = 0;
      for (let k in data.selectResult) {
        let eachSpeciesPrice =
          data.selectResult[k][0].para_08 * data.selectResult[k][0].para_09;
        data.procurePrice += eachSpeciesPrice;
      }

      // 判断总价是否小于零
      if (data.procurePrice <= 0) {
        alert("请输入正确数量");
      }

      // 总价减去优惠金额
      data.procurePrice = data.procurePrice - data.discount;
    };

    // 选择购买数量
    const changeNum = () => {
      for (let k in data.selectResult) {
        data.procurePrice = 0;
      }
    };

    // 提交订单
    const submit = () => {
      if (data.procurePrice == 0) {
        alert("请计算总价");
        return;
      }
      let time = new Date().toLocaleString();
      let orderNo = new Date().getTime(); // 订单号

      setTimeout(() => {
        cancle();
      }, 500);

      for (let i = 0; i <= data.selectResult.length; i++) {
        let dataSql = {};
        dataSql.command = "insert";
        dataSql.para_01 = "订单";
        dataSql.para_02 = time; //下单时间
        dataSql.para_03 = orderNo; //订单编号
        dataSql.para_04 = data.selectResult[i][0].para_04; //商品名
        dataSql.para_05 = ""; //买家
        dataSql.para_06 = data.selectResult[i][0].para_07; //款式
        dataSql.para_07 = data.selectResult[i][0].para_08; //单价
        dataSql.para_08 = data.selectResult[i][0].para_09; //数量
        dataSql.para_09 = "";
        dataSql.para_10 = ""; //收货地
        dataSql.para_11 = data.procurePrice; //订单总价
        dataSql.para_12 = ""; //发货状态
        dataSql.para_13 = data.note_order; //备注
        dataSql.para_14 = "未发货"; //发货状态
        dataSql.para_15 = data.discount; //优惠金额

        axios({
          method: "post",
          url: api.host + api.universal,
          headers: "multipart/form-data",
          data: dataSql,
        });
      }

      // 删除优惠券信息
    };

    // 关闭订单提交页面
    const cancle = () => {
      data.selectResult = [];
      data.procurePrice = 0;
      window();
    };

    const popWindow = (para) => {
      let box = document.querySelector(".popWindow");
      if (para == "open") {
        box.style.display = "block";
      } else {
        box.style.display = "none";
      }
    };
    return {
      GetWeixinQinData,
      ShowPayPopWindow,
      ShowMainImgPopWindow,
      data,
      dataToSql,
      select,
      check,
      window,
      count,
      changeNum,
      submit,
      cancle,
      filter,
      getDataByFamily,
      getImg,
      popWindow,
      getDataByOrder,
      GetNote,
      GetDiscount,
      OpenChangeWindow,
      GetDataById,
      UpdateDataById,
    };
  },
};
</script>

<style lang="less" scoped>
// 修改价格窗口
.popWindow_change {
  display: none;
  .box {
    border: 3px solid gray;
    position: fixed;
    width: 500px;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 90;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btnBox {
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding: 20px 10%;
      .btn {
        padding: 10px 30px;
        background-color: rgb(230, 230, 230);
        border-radius: 5px;
      }
      .btn:hover {
        background-color: rgb(200, 200, 200);
      }
    }
    .inputBox {
      display: flex;
      width: 100%;
      padding: 10px 10%;
      align-items: center;
      .title {
        flex: 1;
        font-weight: bold;
      }
      input {
        flex: 3;
      }
    }
  }
}
// 大图
.mainImg_popWindow {
  display: none;
  .box {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.bold {
  font-weight: bold;
}
.latin_mobile {
  font-style: italic;
}

.window {
  display: none;
  .box {
    position: fixed;
    top: 70px;
    bottom: 8px;
    left: 5%;
    right: 5%;
    background-color: white;
    box-shadow: 0 0 2px 2px rgb(200, 200, 200);
    border-radius: 10px;
    padding: 50px 150px;
    z-index: 99;
    @media only screen and (min-width: 500px) and (max-width: 1000px) {
      padding: 50px 10px;
      bottom: 20vh;
      top: 30vw;
    }
    @media only screen and (max-width: 500px) {
      display: none;
    }
    .submit_box {
      display: flex;
      .submit {
        border: 2px solid rgb(200, 200, 200);
        padding: 10px 20px;
        width: 10%;
        text-align: center;
        border-radius: 10px;
        margin: 10px 10px 0 0;
      }
    }

    .head_box {
      background-color: skyblue;
    }
    .note {
      width: 100%;
      padding: 15px 10px;
      margin: 20px 0 0 0;
      border: 2px solid rgb(200, 200, 200);
      border-radius: 10px;
      height: 50px;
    }
    .parce_box {
      margin: 10px 0 0 0;
      display: flex;
      flex-wrap: wrap;
      .coupon {
        width: 100%;
        display: flex;
        margin: 10px 0;
        .coupon_btn {
          padding: 5px 20px;
          border-radius: 5px;
        }
        .coupon_btn:hover {
          background-color: rgb(200, 200, 200);
        }
      }
      .count {
        border: 2px solid rgb(200, 200, 200);
        padding: 10px 20px;
        width: 10%;
        text-align: center;
        border-radius: 10px;
      }
      .allPrice {
        font-size: 20px;
        margin: 0 0 0 20px;
        // border: 1px solid red;
        display: flex;
        align-items: center;
      }
    }
  }
}

.name_list {
  width: 230px;
  border: 2px solid rgb(167, 167, 167);
  border-radius: 10px;
  padding: 20px;
  position: fixed;
  top: 145px;
  right: 10px;
  @media only screen and (max-width: 1000px) {
    display: none;
    // padding: 10px 10px;
    // width: 60vw;
    // position: fixed;
    // height: 20vh;
    // top: 80vh;
    // left: 40vw;
    // z-index: 90;
    // background-color: white;
  }

  .item {
    margin: 0 0 10px 0;
  }
}

.con_mobile {
  width: 96vw;
  @media only screen and (min-width: 1000px) {
    display: none;
  }
  .check {
    width: 70%;
    height: 70%;
    display: flex;
    justify-content: left;
    color: rgb(77, 153, 184);
    font-weight: bold;
    align-items: center;
    padding: 10px 0;
    border-radius: 5px;
  }
  // .check:hover {
  //   color: black;
  //   background-color: rgb(220, 220, 220);
  // }
  .item_mobile {
    border: 2px solid rgb(200, 200, 200);
    border-radius: 10px;
    padding: 1vh 3vw;
    margin: 0 0 1vh 0;
    .ite_img {
      display: flex;
      justify-content: center;
      margin: 0 0 1vh 0;
      // width: 50vw;
      img {
        width: 300px;
      }
    }
    .ite {
      display: flex;
      justify-content: left;
      padding: 0 0 0.5vh 0;
      .ite_01 {
        padding: 0 2vw 0 0;
      }
    }
  }
}
.pc_only {
  display: none;
  @media only screen and (max-width: 1000px) {
    display: none;
  }
}
.checkBox {
  display: flex;
  justify-content: center;
  padding: 0;
  .check {
    width: 70%;
    height: 70%;
    display: flex;
    justify-content: center;
    color: rgb(77, 153, 184);
    font-weight: bold;
    align-items: center;
    padding: 0;
    border-radius: 5px;
  }
  .check:hover {
    color: black;
    background-color: rgb(220, 220, 220);
  }
}

.popWindow {
  display: none;
  .shell {
    position: fixed;
    top: 20%;
    bottom: 20%;
    left: 20%;
    right: 20%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 2px 2px rgb(200, 200, 200);
    z-index: 99;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    .payBox {
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      text-align: center;
      height: 100%;
      width: 50%;
      .note {
        font-size: 20px;
        margin: 10px 0 0 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px;
      }
    }
  }
}
.PaymentCodeWindow {
  display: none;
  .box {
    position: fixed;
    top: 20%;
    bottom: 20%;
    left: 20%;
    right: 20%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 2px 2px rgb(200, 200, 200);
    z-index: 99;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    .payBox {
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      text-align: center;
      height: 100%;
      width: 50%;
      .note {
        font-size: 20px;
        margin: 10px 0 0 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px;
      }
    }
  }
}
.box {
  padding: 45px 0 0 0;
  .con {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1000px) {
      display: none;
    }
    .td_0_5 {
      display: flex;
      align-items: center;
      flex: 0.5;
    }
    .td_01 {
      display: flex;
      align-items: center;
      flex: 1;
    }
    .td_02 {
      display: flex;
      align-items: center;
      flex: 2;
    }
    .td_03 {
      display: flex;
      align-items: center;
      flex: 1.1;
    }
    .table {
      width: 100%;
      padding: 0 260px 0 0;
    }
  }

  .search_box {
    width: 100%;
    height: 90px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    position: fixed;
    top: 80px;
    padding: 10px 0 10px 0;
    align-items: center;
    flex-wrap: wrap;
    @media only screen and (max-width: 1000px) {
      width: 100vw;
      flex-wrap: nowrap;
      top: 6vh;
    }
    .note {
      width: 100%;
      text-align: center;
      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }
    select {
      padding: 9px 20px;
      margin: 0 20px 0 0;
      border-radius: 5px;
      border: 2px solid rgb(200, 200, 200);
      @media only screen and (max-width: 1000px) {
        padding: 5px;
      }
    }
  }
  .select_btn {
    // margin: 0 0 0 20px;
    color: rgb(77, 153, 184);
    font-weight: bold;
    padding: 5px 20px;
    border-radius: 5px;
    @media only screen and (min-width: 500px) and (max-width: 1000px) {
      display: none;
      // position: fixed;
      // top: 13vh;
    }
    @media only screen and (max-width: 500px) {
      display: none;
      // position: fixed;
      // top: 15vh;
    }
  }
  .select_btn:hover {
    background-color: rgb(200, 200, 200);
    color: black;
  }
  input {
    border: 2px solid rgb(200, 200, 200);
    border-radius: 5px;
    padding: 8px 10px;
    // height: 100%;
    margin: 0 20px 0 0;
    text-align: left;
    @media only screen and (max-width: 1000px) {
      width: 30vw;
    }
  }

  table {
    width: 100%;
    .tableHead {
      background-color: rgb(180, 223, 239);
      font-weight: bold;
    }
    tr {
      display: flex;
      justify-content: space-between;
    }
    td {
      flex: 1;
      padding: 5px 20px;
      border: 1px solid gray;
      input {
        width: 100%;
        height: 100%;
        border: none;
        padding: 0 5px;
      }
    }
  }
  .goodList {
    display: flex;
    .change {
      font-weight: bold;
      color: skyblue;
    }
    .change:hover {
      background-color: rgb(200, 200, 200);
      color: black;
    }

    .img_box {
      width: 100%;
      // border: 1px solid gray;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .latin {
      font-style: italic;
    }
    // .check {
    //   color: skyblue;
    //   font-weight: bold;
    // }
    // .check:hover {
    //   color: red;
    // }
  }
}
</style>
