<template>
  <div>
    <!-- <div class="title">{{mydata.title}}</div>    -->
    <div class="con">
      <div v-for="(item, index) in mydata.data" :key="index" class="imgShell">
        <div class="imageBox" @click="loadHdr(item.para_03)">
          <img
            :src="
              'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
              item.para_09
            "
            alt
          />
        </div>
        <div>{{ item.id }}</div>
        <div>{{ item.para_02 }}</div>
      </div>
    </div>
    <div class="conRight"></div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import api from "@/api/index";
import axios from "axios";
import { onMounted, reactive } from "vue";
import { renderer, loadHdr } from "@/threejs/HDR";
export default {
  setup() {
    const $router = new useRouter();
    let mydata = reactive({
      data: {},
      hdr: {},
      title: "",
    });
    let dataToSql = reactive({
      command: "",
      id: null,
      para_01: "HDR数据库",
      para_02: "",
      para_03: "",
      para_04: "",
      para_05: "",
      para_06: "",
      para_07: "",
      para_08: "",
      para_09: "",
      para_10: "",
      para_11: "",
      para_12: "",
      para_13: "",
      para_14: "",
      para_15: "",
      input: "中昆",
      page: 1,
    });

    onMounted(() => {
      axiosGet("searchNum");
      openHdr();
      loadHdr("微信图片_20221127170849.hdr");
    });

    const openHdr = (name) => {
      let canvas = document.querySelector(".conRight");
      canvas.appendChild(renderer.domElement);
    };

    const getData = () => {
      axiosGet("selectByPara_01Num");
    };
    const axiosGet = (command) => {
      dataToSql.command = command;
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        mydata.data = res.data.results;
      });
    };

    return {
      axiosGet,
      getData,
      openHdr,
      mydata,
      dataToSql,
      renderer,
      loadHdr,
    };
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}
.imgShell {
  width: 250px;
  height: 300px;
  //   border: 1px solid red;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 500px) {
    width: 100vw;
    height: auto;
    // border: 1px solid black;
    margin: 0 0 3vh 0;
  }
}

.con {
  /* width: 100%; */
  margin: 20px 1030px 0 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid black; */
  justify-content: space-between;
  @media only screen and (max-width: 500px) {
    margin: 0;
  }
}
.conRight {
  position: fixed;
  width: 1000px;
  height: 800px;
  top: 100px;
  right: 20px;
  border: 1px solid gray;
  @media only screen and (max-width: 500px) {
    display: none;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageBox {
  width: 200px;
  height: 200px;
  border: 1px solid gray;
  margin-bottom: 10px;
  border-radius: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}
</style>
