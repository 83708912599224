<template>
  <div class="threeBox">
    <div class="canvas">{{ glbName }}</div>
  </div>
</template>

<script setup>
import api from "@/api/index";
import axios from "axios";
import { useRoute } from "vue-router";
import { renderer, loadModel, changeModel } from "@/myJs/threeMobile";
import { onMounted } from "vue";

let route = new useRoute();
let glbName = route.query.value1;

onMounted(() => {
  let canvas = document.querySelector(".canvas");
  canvas.appendChild(renderer.domElement);
  setTimeout(() => {
    changeModel(glbName);
  }, 100);
});
</script>

<style lang="less" scoped>
.threeBox {
  position: fixed;
  top: 0;
  left: 0;
  //   background-color: black;
  width: 100vw;
  height: 100vh;
  color: white;
  justify-content: center;
  align-items: center;
  .canvas {
    // border: 1px solid red;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>
