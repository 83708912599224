import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

let scene, camera, renderer, meshGlb, evTexture;
let width = window.screen.width;
let height = window.screen.height;

const initScene = () => {
  scene = new THREE.Scene();
  camera = new THREE.Camera();
  renderer = new THREE.WebGLRenderer({
    antialias: true,
  });

  camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 500);
  camera.position.set(30, 30, 30);
  camera.lookAt(0, 0, 0);

  // let geo = new THREE.BoxGeometry(10, 10, 10);
  // let material = new THREE.MeshPhysicalMaterial({
  //   color: 0xff5533,
  // });
  // let mesh = new THREE.Mesh(geo, material);
  // scene.add(mesh);

  renderer.setSize(width, height);
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  //   色调映射曝光度;
  renderer.toneMappingExposure = 1;
  //   设置渲染器开启阴影贴图;
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;

  scene.add(camera);
};

// 载入贴图 线上
const loadHdr = () => {
  let path =
    "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/glb/kloppenheim_06_puresky_4k.hdr";

  let loader = new RGBELoader();
  loader.load(path, function (texture) {
    texture.mapping = THREE.EquirectangularReflectionMapping;
    scene.background = texture;
    scene.environment = texture;
  });
};
// 载入贴图 本地
// const loadHdr = () => {
//   let path = "1711586933899.hdr";

//   let loader = new RGBELoader();
//   loader.load(path, function (texture) {
//     texture.mapping = THREE.EquirectangularReflectionMapping;
//     scene.background = texture;
//     scene.environment = texture;
//   });
// };

// 线上
const loadModel = (name) => {
  let path =
    "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/glb/" +
    name;
  let loader = new GLTFLoader();
  console.log(name);
  loader.load(path, (gltf) => {
    meshGlb = gltf.scene;
    meshGlb.scale.set(10, 10, 10);
    meshGlb.physicallyCorrectLights = true;
    meshGlb.toneMapping = THREE.ACESFilmicToneMapping;
    meshGlb.toneMappingExposure = 1;
    scene.add(meshGlb);
  });
};

// 本地
// const loadModel = (name) => {
//   let path = "saber_lily_-_distant_avalon_figure.glb";
//   let loader = new GLTFLoader();
//   console.log(name);
//   loader.load(path, (gltf) => {
//     meshGlb = gltf.scene;
//     meshGlb.scale.set(0.2, 0.2, 0.2);
//     meshGlb.physicallyCorrectLights = true;
//     meshGlb.toneMapping = THREE.ACESFilmicToneMapping;
//     meshGlb.toneMappingExposure = 1;
//     scene.add(meshGlb);
//   });
// };

const changeModel = (name) => {
  // 去除之前的模型
  scene.remove(meshGlb);
  //   载入新模型
  setTimeout(() => {
    loadModel(name);
  }, 500);
};

const initLight = () => {
  let light_01 = new THREE.AmbientLight(0xffffff, 2);
  scene.add(light_01);
};

const initControl = () => {
  let controls = new OrbitControls(camera, renderer.domElement);
  controls.enableZoom = true;
};

const render = () => {
  renderer.render(scene, camera);
  requestAnimationFrame(render);
};

initScene();
loadModel();
loadHdr();
initControl();
render();

export { renderer, loadModel, changeModel };
