<template>
  <div>
    <div class="con">
      <div class="title">
        <div class="title_para_01">{{ data.style }}</div>
        <div>
          <input
            type="text"
            v-model="data.input"
            @keydown.enter="searchData()"
            @keydown.esc="initData()"
          />
        </div>
        <div class="searchBar" @click="searchData()">搜索</div>
        <div class="searchBar" @click="initData()">清除</div>
        <select
          name=""
          id=""
          v-model="data.page"
          @click="getNextPageData(data.page)"
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <!-- <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option> -->
        </select>
      </div>
      <div class="imgShell" v-for="(item, index) in data.data" :key="index">
        <div class="imageBox" @click="mainBox('open', item.para_10)">
          <img
            :src="
              'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
              item.para_09
            "
            alt=""
            class="image"
          />
        </div>
        <div class="font_bold">{{ item.para_04 }}</div>
        <div class="latin">{{ item.para_05 }}</div>
        <div class="order_family">{{ item.para_02 + " " + item.para_03 }}</div>
      </div>
    </div>
    <!-- 大图 -->
    <div class="mainImgWindow">
      <div class="mainBox" @click="mainBox('close')">
        <img
          class="mainImg"
          :src="
            'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
            data.mainSrc
          "
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/index";
import axios from "axios";
import { computed, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    let route = useRoute();
    let data = reactive({
      data: {},
      type: {},
      select: "",
      style: "昆虫",
      //   style: route.params,
      mainSrc: "", // 大图
      input: "",
      page: 1,
    });
    let dataToSql = reactive({
      command: "",
      id: null,
      para_01: "",
      para_02: "",
      para_03: "",
      para_04: "",
      para_05: "",
      para_06: "",
      para_07: "",
      para_08: "",
      para_09: "",
      para_10: "",
      para_11: "",
      para_12: "",
      para_13: "",
      para_14: "",
      para_15: "",
      page: 1,
      input: "",
    });
    onMounted(() => {
      if (data.style == "昆虫") {
        dataToSql.para_01 = "昆虫查询系统";
        dataToSql.input = "查询系统";
        dataToSql.command = "search";
        console.log(route.query);
        getInsectData();
      } else {
        if (data.style == "哺乳动物") {
          dataToSql.para_01 = "哺乳类查询系统";
        } else if (data.style == "鸟类") {
          dataToSql.para_01 = "鸟类查询系统";
        } else if (data.style == "两栖类") {
          dataToSql.para_01 = "两栖类查询系统";
        } else if (data.style == "爬行类") {
          dataToSql.para_01 = "爬行类查询系统";
        } else if (data.style == "水生动物") {
          dataToSql.para_01 = "鱼类查询系统";
        } else if (data.style == "植物") {
          dataToSql.para_01 = "植物查询系统";
        } else if (data.style == "菌类") {
          dataToSql.para_01 = "菌类查询系统";
        }

        getData("selectByPara_01");
      }
    });

    // 打开、关闭大图
    const mainBox = (order, mainSrc) => {
      let window = document.querySelector(".mainImgWindow");
      let mainBox = document.querySelector(".mainBox");
      let mainImg = document.querySelector(".mainImg");
      if (order == "open") {
        window.style.display = "block";
        data.mainSrc = mainSrc;
        mainBox.style.width = mainImg.width;
      } else if (order == "close") {
        window.style.display = "none";
        data.mainSrc = "";
      }
    };

    // 获取昆虫数据
    const getInsectData = () => {
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        data.data = res.data.results;
      });
    };

    // 获取全部数据
    const getData = (command) => {
      let input = data.input.trim().toLowerCase();
      dataToSql.input = input;
      dataToSql.command = command;
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        data.data = res.data.results;
      });
    };

    // 搜索
    const searchData = () => {
      let input = data.input.trim();
      if (input == "") {
        alert("搜索框为空");
      } else {
        data.page = 1;
        dataToSql.page = 1;
        setTimeout(() => {
          getData("search");
        }, 100);
      }
    };

    // 初始化数据
    const initData = () => {
      console.log("init");
      data.input = "";
      dataToSql.page = 1;
      if (dataToSql.para_01 == "昆虫查询系统") {
        dataToSql.input = "查询系统";
        dataToSql.command = "search";
        getInsectData();
      } else {
        getData("selectByPara_01");
      }
    };

    const getNextPageData = (page) => {
      dataToSql.page = page;
      if (dataToSql.para_01 == "昆虫查询系统") {
        // 昆虫
        getInsectData();
      } else {
        // 其他数据
        if (data.input.trim().toLowerCase() == "") {
          getData("selectByPara_01");
        } else {
          getData("search");
        }
      }
    };

    return {
      initData,
      searchData,
      data,
      getData,
      mainBox,
      getNextPageData,
      getInsectData,
    };
  },
};
</script>

<style lang="less" scoped>
.font_bold {
  font-weight: bold;
}
.latin {
  font-style: italic;
  margin: 5px 0;
  width: 100%;
  text-align: center;
}
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mainImgWindow {
  display: none;
  .mainBox {
    display: flex;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
}
.clear {
  padding: 10px 20px;
  border: 2px solid gray;
  border-radius: 5px;
  margin: 0 0 0 10px;
  font-size: 18px;
  background-color: rgb(241, 241, 241);
}
.select {
  padding: 10px 20px;
  border: 2px solid gray;
  border-radius: 5px;
  margin: 0 0 0 20px;
  font-size: 18px;
  background-color: rgb(241, 241, 241);
}
.input_box {
  // position: fixed;
  top: 0;
  padding: 0 0 20px 0;
  width: 100%;
  background-color: white;
  display: flex;
  margin: 0 0 20px 0;
}
input {
  width: 30%;
  padding: 10px;
  border: 2px solid gray;
  border-radius: 5px;
  margin: 0 0 0 10px;
  background-color: rgb(241, 241, 241);
  text-align: left;
}
.con {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 80px 0 0 0;
  .title {
    // font-size: 28px;
    // font-weight: bold;
    text-align: center;
    padding: 20px 0 10px 0;
    position: fixed;
    background-color: rgb(255, 255, 255);
    top: 70px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .title_para_01 {
      @media only screen and (max-width: 500px) {
        display: none;
      }
    }
    select {
      padding: 5px 10px;
      border: 2px solid gray;
      border-radius: 5px;
    }
    input {
      width: 200px;
      padding: 5px 5px;
      @media only screen and (max-width: 500px) {
        width: 30vw;
      }
    }
    .searchBar {
      font-size: 20px;
      margin: 0 0 0 10px;
      padding: 5px 15px;
      border-radius: 5px;
    }
    .searchBar:hover {
      background-color: rgb(200, 200, 200);
    }
  }
}
.imgShell {
  width: 300px;
  // height: 300px;
  // border: 1px solid rgb(255, 120, 120);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px 50px 10px;
  box-shadow: 0 0 2px 2px rgb(200, 200, 200);
  padding: 20px;
  border-radius: 10px;

  @media only screen and (max-width: 1000px) {
    width: 40vw;
    box-shadow: none;
    // height: 50vw;
  }
  .order_family {
    font-size: 15px;
  }
}
.imgShell:hover {
  background-color: rgb(240, 240, 240);
}
.imageBox {
  width: 220px;
  height: 220px;
  // border: 1px solid black;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  @media only screen and (max-width: 1000px) {
    width: 38vw;
    height: 38vw;
  }
}
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
