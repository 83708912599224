<template>
  <div class="insectBreed">
    <div class="IB_box">
      <div class="IB_box_01">
        <div class="title">昆虫繁育</div>
        <div class="text">
          公司饲养殖种类：鳞翅目26种、鞘翅目：18种、螳螂目：16种、竹节虫目12种。
        </div>
        <div class="text">年产量：60多万。只</div>
        <div class="text">
          资质：公司拥有野生动物驯养繁殖许可证、昆虫相关专利13项、软著15项。
        </div>
        <div class="img_box">
          <img :src="imgList.list_02_b[10]" alt="" />
        </div>
      </div>
      <div class="IB_box_02">
        <img :src="imgList.list_02[0]" alt="" />
      </div>
    </div>
    <div class="bottom_box">
      <img :src="imgList.list_02_b[0]" alt="" />
      <img :src="imgList.list_02_b[1]" alt="" />
      <img :src="imgList.list_02_b[2]" alt="" />
      <img :src="imgList.list_02_b[3]" alt="" />
      <img :src="imgList.list_02_b[4]" alt="" />
      <img :src="imgList.list_02_b[5]" alt="" />
      <img :src="imgList.list_02_b[6]" alt="" />
      <img :src="imgList.list_02_b[7]" alt="" />
      <img :src="imgList.list_02_b[8]" alt="" />
      <img :src="imgList.list_02_b[9]" alt="" />
    </div>
    <ContactInformation />
  </div>
</template>

<script>
import ContactInformation from "./ContactInformation.vue";
import imgList from "@/myJs/imgList";

export default {
  components: {
    ContactInformation,
  },
  setup() {
    return {
      imgList,
      ContactInformation,
    };
  },
};
</script>

<style lang="less" scoped>
.insectBreed {
  background-color: rgb(255, 255, 255);
  .IB_box {
    height: 495px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .IB_box_01 {
      width: 47%;
      height: 100%;
      // background-color: rgb(197, 167, 167);

      display: flex;
      flex-direction: column;
      align-items: center;
      .img_box {
        margin: 10px 0 0 0;

        img {
          height: 250px;
          object-fit: contain;
        }
      }
      .title {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        margin: 10px;
      }
      .text {
        width: 80%;
        text-align: left;
        margin: 5px 0;
        font-size: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .IB_box_02 {
      height: 495px;

      display: flex;
      justify-content: right;
      // border: 1px solid red;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        // border: 1px solid rgb(57, 23, 208);
      }
    }
  }
  .bottom_box {
    margin: 10px 0;
    height: 150px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    img {
      width: auto;
      height: 150px;
      margin: 0 5px;
    }
  }
}
</style>
