<template>
  <div @click="insertData">插入</div>
  <!-- <div @click="deleteAllDta">删除</div> -->
</template>

<script setup>
import api from "@/api/index";
import axios from "axios";
import data_qingtign from "@/myJs/new";
import { onMounted, reactive } from "vue";
let data = reactive({});
let dataToSql = reactive({
  id: null,
  command: "insert",
  para_01: "中昆_图库",
  para_02: "",
  para_03: "",
  para_04: "",
  para_05: "",
  para_06: "",
  para_07: "",
  para_08: "",
  para_09: "1715304239326.png",
  para_10: "1715304239278.webp",
  para_11: "",
  para_12: "",
  para_13: "",
  para_14: "",
  para_15: "",
});
const insertData = () => {
  data = data_qingtign.qingtingList;
  console.log(data.length);
  for (let i = 0; i < data.length; i++) {
    setTimeout(() => {
      dataToSql.para_08 = data[i][0].para_02;
      dataToSql.para_10 = data[i][1].para_10;
      dataToSql.para_09 = data[i][2].para_09;
      // console.log(dataToSql);
      // if (data[i][1].para_03 == "m") {
      //   dataToSql.para_07 = "雄";
      // } else if (data[i][2].para_03 == "f") {
      //   dataToSql.para_07 = "雌";
      // } else {
      //   dataToSql.para_07 = data[i][2].para_03;
      // }

      axios({
        method: "post",
        url: api.host + api.universal,
        headers: "multipart/form-data",
        data: dataToSql,
      });
    }, i * 1000);
  }
};

const deleteAllDta = () => {
  axios({
    method: "post",
    url: api.host + api.universal,
    headers: "multipart/form-data",
    data: {
      command: "deleteBy1",
      para_01: "蜻蜓整套名录",
    },
  });
};
</script>

<style></style>
