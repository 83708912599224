<template>
  <!-- 原蝶 -->
  <div
    class="priceBox"
    v-show="data.data.length > 0 && data.style == '全部原蝶'"
  >
    <div @click="toExcel" class="download">导出</div>
    <table border="1" id="table_report">
      <tr>
        <th>ID</th>
        <th>中文名</th>
        <th>拉丁名</th>
        <th>分类</th>
        <th>尺寸</th>
        <th>价格</th>
        <th>图片</th>
      </tr>
      <tr v-for="(item, index) in data.data" :key="index">
        <td class="text">{{ item.id }}</td>
        <td class="text">{{ item.para_04 }}</td>
        <td class="text">{{ item.para_05 }}</td>
        <td class="text">{{ item.para_02 + " " + item.para_03 }}</td>
        <td class="text">{{ item.para_06 }}</td>
        <td class="text">{{ item.para_08 }}</td>
        <td class="miniImg"><img :src="getUrl(item.para_09)" alt="" /></td>
      </tr>
    </table>
  </div>

  <!-- 非原蝶 -->
  <div
    class="priceBox"
    v-show="data.data.length > 0 && data.style != '全部原蝶'"
  >
    <div @click="toExcel" class="download">导出</div>
    <table border="1" id="table_report">
      <tr>
        <th>ID</th>
        <th>名称</th>
        <th>{{ data.th_05 }}</th>
        <th>{{ data.th_06 }}</th>
        <th>价格</th>
        <th>数量</th>
        <th>{{ data.th_12 }}</th>
        <th>图片</th>
      </tr>
      <tr v-for="(item, index) in data.data" :key="index">
        <td class="text">{{ item.id }}</td>
        <td class="text">{{ item.para_02 }}</td>
        <td class="text">{{ item.para_05 }}</td>
        <td class="text">{{ item.para_06 }}</td>
        <td class="text">{{ item.para_07 }}</td>
        <td class="text">1</td>
        <td class="text">{{ item.para_12 }}</td>
        <td class="miniImg"><img :src="getUrl(item.para_09)" alt="" /></td>
      </tr>
    </table>
  </div>

  <div class="inputBox" ref="myRef">
    <div class="boxPosition">
      <div class="inputShell">
        <input type="text" v-model="data.input" @keypress.enter="start" />
      </div>
    </div>
  </div>
</template>

<script setup>
import api from "@/api/index";
import axios from "axios";
import getUrl from "@/myJs/getUrl";
import { onMounted, reactive, ref } from "vue";
import ExcelJs from "exceljs";

const workbook = new ExcelJs.Workbook();

let myRef = ref(null);
let conBox = ref(null);
let conBoxLabel = ref(null);
let data = reactive({
  style: "",
  labelStyle: "",
  data: {},
  input: "",
  dataLabel: [],
  th_02: "名称",
  th_05: "规格",
  th_06: "颜色",
  th_07: "价格",
  th_12: "单位",
});
onMounted(() => {
  // data.input = "全部";
  // start();
});

const toExcel = () => {
  //window.location.href='<%=basePath%>pmb/excelShowInfo.do';
  //获取表格
  var exportFileContent = document.getElementById("table_report").outerHTML;
  //设置格式为Excel，表格内容通过btoa转化为base64，此方法只在文件较小时使用(小于1M)
  //exportFileContent=window.btoa(unescape(encodeURIComponent(exportFileContent)));
  //var link = "data:"+MIMEType+";base64," + exportFileContent;
  //使用Blob
  var blob = new Blob([exportFileContent], {
    type: "text/plain;charset=utf-8",
  }); //解决中文乱码问题
  blob = new Blob([String.fromCharCode(0xfeff), blob], { type: blob.type });
  //设置链接
  var link = window.URL.createObjectURL(blob);
  let timer = new Date();
  var a = document.createElement("a"); //创建a标签
  a.download = timer.toLocaleString() + "_" + "中昆报价表.xls"; //设置被下载的超链接目标（文件名）
  a.href = link; //设置a标签的链接
  document.body.appendChild(a); //a标签添加到页面
  a.click(); //设置a标签触发单击事件
  document.body.removeChild(a); //移除a标签
};

const start = () => {
  if (data.input.trim() == "") {
    return alert("请输入标签");
  }
  let data_01 = {
    para_01: "成品标本",
    para_08: "",
    command: "",
    input: data.input,
    page: "1",
  };
  if (data.input == "全部成品") {
    (data_01.para_01 = "成品标本"), (data_01.command = "selectByPara_01Num");
    getData(data_01);
  } else if (data.input == "全部原蝶") {
    data.th_05 = "拉丁名";
    data.th_06 = "分类";
    data.th_12 = "尺寸";
    data_01.para_01 = "中昆商品";
    data_01.command = "selectByPara_01Num";
    getData(data_01);
  } else {
    data_01.para_01 = "小程序_订单";
    data_01.command = "searchNum";
    searchData(data_01);
  }
};

const searchData = (value) => {
  // 获取订单
  axios({
    method: "get",
    url: api.host + api.selectUniversal,
    params: value,
  }).then((res) => {
    let a = res.data.results;

    if (a.length == 0) {
      return alert("标签错误");
    }
    // id数据
    let para_14 = res.data.results[0].para_14;
    // 是否是网页订单_原蝶
    data.labelStyle = res.data.results[0].para_15;

    let idBox = para_14.split("  ");

    for (let i = 0; i < idBox.length; i++) {
      // 根据id获取数据
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: {
          command: "selectById",
          id: idBox[i],
        },
      }).then((res) => {
        let item;
        if (data.labelStyle == "网页订单_原蝶") {
          console.log("原蝶");
          data.th_05 = "拉丁名";
          data.th_06 = "分类";
          data.th_12 = "尺寸";
          item = {
            id: res.data.results[0].id,
            para_01: res.data.results[0].para_01,
            para_02: res.data.results[0].para_04, //中文名
            para_03: res.data.results[0].para_03,
            para_04: res.data.results[0].para_04,
            para_05: res.data.results[0].para_05, //规格/拉丁名
            para_06:
              res.data.results[0].para_02 + " " + res.data.results[0].para_03, //颜色/分类
            para_07: res.data.results[0].para_08, //价格
            para_08: res.data.results[0].para_08,
            para_09: res.data.results[0].para_09,
            para_10: res.data.results[0].para_10,
            para_11: res.data.results[0].para_11,
            para_12: res.data.results[0].para_06, //单位/尺寸
            para_13: res.data.results[0].para_13,
            para_14: res.data.results[0].para_14,
            para_15: res.data.results[0].para_15,
          };
        } else {
          data.th_05 = "规格";
          data.th_06 = "颜色";
          data.th_12 = "单位";
          item = {
            id: res.data.results[0].id,
            para_01: res.data.results[0].para_01,
            para_02: res.data.results[0].para_02,
            para_03: res.data.results[0].para_03,
            para_04: res.data.results[0].para_04,
            para_05: res.data.results[0].para_05,
            para_06: res.data.results[0].para_06,
            para_07: res.data.results[0].para_07,
            para_08: res.data.results[0].para_08,
            para_09: res.data.results[0].para_09,
            para_10: res.data.results[0].para_10,
            para_11: res.data.results[0].para_11,
            para_12: res.data.results[0].para_12,
            para_13: res.data.results[0].para_13,
            para_14: res.data.results[0].para_14,
            para_15: res.data.results[0].para_15,
          };
        }

        data.dataLabel.push(item);

        data.data = data.dataLabel;
      });
    }

    myRef.value.style.display = "none";
  });
};

const getData = (value) => {
  data.style = data.input;
  axios({
    method: "get",
    url: api.host + api.selectUniversal,
    params: value,
  }).then((res) => {
    if (data.data.length == 0) {
      return alert("标签错误");
    }
    data.data = res.data.results;
    console.log(data.data);
    myRef.value.style.display = "none";
  });
};
</script>

<style lang="less" scoped>
.priceBox {
  width: 100%;
  left: 0;
  z-index: 99;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;

  // display: none;
  .download {
    padding: 20px;
    width: 100%;
    background-color: white;
    background-color: white;
    display: flex;
    justify-content: center;
    font-weight: bold;
  }
  .download:hover {
    color: rgb(255, 177, 55);
  }
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }
  th {
    background-color: skyblue;
    padding: 10px 30px;
    font-weight: bold;
  }
  .text {
    padding: 0 30px;
  }
  .miniImg {
    width: 10%;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.inputBox {
  display: block;
  .boxPosition {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: rgb(240, 240, 240);
    .inputShell {
      width: 500px;
      height: 300px;
      // border: 1px solid red;
      border-radius: 10px;
      background-color: white;
      box-shadow: 0 0 3px 3px rgb(200, 200, 200);
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        width: 70%;
        padding: 10px 0;
        border: 2px solid gray;
        border-radius: 5px;
      }
    }
  }
}
</style>
