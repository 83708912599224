<template>
  <div class="scienceResearch">
    <img :src="imgList.list_03[0]" alt="" />
    <img :src="imgList.list_03[1]" alt="" />
    <img :src="imgList.list_03[2]" alt="" />
    <div class="box_01">
      <img :src="imgList.list_03[3]" alt="" />
    </div>
    <ContactInformation />
  </div>
  <!-- 手机版 -->
  <div class="mobile_con">
    <img :src="imgList.list_03[0]" alt="" />
    <img :src="imgList.list_03[1]" alt="" />
    <img :src="imgList.list_03[2]" alt="" />
    <div class="box_01">
      <!-- <img :src="imgList.list_03[3]" alt="" /> -->
    </div>
  </div>
</template>

<script>
import ContactInformation from "./ContactInformation.vue";
import imgList from "@/myJs/imgList";

export default {
  components: {
    ContactInformation,
  },
  setup() {
    return {
      imgList,
      ContactInformation,
    };
  },
};
</script>

<style lang="less" scoped>
.mobile_con {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0 10vh 0;
  padding: 0;
  @media only screen and (min-width: 800px) {
    display: none;
  }
  img {
    width: 100vw;
    object-fit: contain;
  }
  .box_01 {
    margin: 0;
  }
}
.scienceResearch {
  width: 100%;
  @media only screen and (max-width: 800px) {
    display: none;
  }
  img {
    display: block;
    position: relative;
  }
  .box_01 {
    position: absolute;
    width: 250px;
    height: 250px;
    top: 6480px;
    right: 150px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
