<template>
  <div class="insectCraft">
    <img :src="imgList.list_01[0]" alt="" />
    <img :src="imgList.list_01[1]" alt="" />
    <img :src="imgList.list_01[2]" alt="" />
    <ContactInformation />
  </div>
  <!-- 手机版 -->
  <div class="mobile_con">
    <img :src="imgList.list_01[0]" alt="" />
    <img :src="imgList.list_01[1]" alt="" />
    <img :src="imgList.list_01[2]" alt="" />
  </div>
</template>

<script>
import ContactInformation from "./ContactInformation.vue";
import imgList from "@/myJs/imgList";

export default {
  components: {
    ContactInformation,
  },
  setup() {
    return {
      imgList,
      ContactInformation,
    };
  },
};
</script>

<style lang="less" scoped>
.mobile_con {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0 10vh 0;
  padding: 0;
  @media only screen and (min-width: 800px) {
    display: none;
  }
  img {
    width: 100vw;
    object-fit: contain;
  }
}
.insectCraft {
  @media only screen and (max-width: 800px) {
    display: none;
  }
  img {
    margin: 0;
    padding: 0;
    display: block;
  }
}
</style>
