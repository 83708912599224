export default {
  // 展品礼品定制
  list_01: [
    require("@/assets/164583407095424b79f75e11c4f96.webp"),
    require("@/assets/164576993095472aa0d5b8458be6d.webp"),
    require("@/assets/16457700007458641322f136adbb4.webp"),
  ],
  //  昆虫繁育页面  上半页
  list_02: [require("@/assets/1647055623730fc321fcd0abec36b.webp")],
  //  昆虫繁育页面  下半页
  list_02_b: [
    require("@/assets/k1.webp"),
    require("@/assets/k2.webp"),
    require("@/assets/k3.webp"),
    require("@/assets/k4.webp"),
    require("@/assets/k5.webp"),
    require("@/assets/k6.webp"),
    require("@/assets/k7.webp"),
    require("@/assets/k8.webp"),
    require("@/assets/k9.webp"),
    require("@/assets/k10.webp"),
    require("@/assets/k11.webp"),
  ],

  //  科普研学页面
  list_03: [
    require("@/assets/0001.webp"),
    require("@/assets/0004.webp"),
    require("@/assets/0002.webp"),
    require("@/assets/0006.gif"),
  ],
  //  展馆设计施工页面
  list_04: [
    require("@/assets/a1.webp"),
    require("@/assets/a2.webp"),
    require("@/assets/a3.webp"),
  ],
  //  昆虫标本页面
  list_05: [
    require("@/assets/b1.webp"),
    require("@/assets/b2.png"),
    require("@/assets/b3.webp"),
    require("@/assets/b4.webp"),
    require("@/assets/b5.webp"),
    require("@/assets/b6.webp"),
    require("@/assets/b7.webp"),
    require("@/assets/b8.webp"),
    require("@/assets/b9.webp"),
    require("@/assets/b10.webp"),
    require("@/assets/b11.webp"),
    require("@/assets/b12.webp"),
    require("@/assets/b13.webp"),
  ],
  //  首页
  list_06: [
    require("@/assets/c1.webp"),
    require("@/assets/c2.webp"),
    require("@/assets/c3.webp"),
    require("@/assets/c4.webp"),
    require("@/assets/c5.webp"),
    require("@/assets/c6.png"),
    require("@/assets/l1.webp"),
  ],
  //  首页 中间
  list_07: [
    require("@/assets/d2.webp"),
    require("@/assets/d3.webp"),
    require("@/assets/d4.webp"),
    require("@/assets/d5.webp"),
    require("@/assets/d6.webp"),
  ],
  // 首页
  list_home: [
    require("@/assets/f0.webp"),
    require("@/assets/f1.webp"),
    require("@/assets/f2.webp"),
    require("@/assets/f3.webp"),
    require("@/assets/f4.webp"),
  ],
  //  首页 底部
  list_08: [
    require("@/assets/e1.webp"),
    require("@/assets/e2.webp"),
    require("@/assets/e3.webp"),
    require("@/assets/e4.webp"),
    require("@/assets/e5.webp"),
    require("@/assets/e6.webp"),
    require("@/assets/e7.webp"),
    require("@/assets/e8.webp"),
  ],
  //  昆虫馆
  list_09: [
    require("@/assets/h1.webp"),
    require("@/assets/h2.webp"),
    require("@/assets/h3.webp"),
    require("@/assets/h4.webp"),
  ],
  //  植物馆
  list_10: [
    require("@/assets/i1.webp"),
    require("@/assets/i2.webp"),
    require("@/assets/i3.webp"),
    require("@/assets/i4.webp"),
    require("@/assets/i5.webp"),
  ],
  //  动物馆
  list_11: [
    require("@/assets/j1.webp"),
    require("@/assets/j2.webp"),
    require("@/assets/j3.webp"),
    require("@/assets/j4.webp"),
    require("@/assets/j5.webp"),
    require("@/assets/j6.webp"),
  ],
  // 图标 二维码
  icon: [
    require("@/assets/g1.png"),
    require("@/assets/g2.png"),
    require("@/assets/g3.png"),
    require("@/assets/g4.png"),
    require("@/assets/首页.png"),
    require("@/assets/昆虫类.png"),
    require("@/assets/展馆.png"),
    require("@/assets/科普.png"),
    require("@/assets/展品.png"),
  ],
};
