<template>
  <div class="bottom">
    <div class="box_02">
      <div class="box_02_01">
        <div class="left">
          <div class="title">联系方式</div>
          <div class="item">
            <div class="icon">
              <img :src="imgList.icon[1]" alt="" />
            </div>
            <div class="text">butterfly</div>
          </div>
          <div class="item">
            <div class="icon">
              <img :src="imgList.icon[2]" alt="" />
            </div>
            <div class="text">13623828327 丁先生</div>
          </div>
          <div class="item">
            <div class="icon">
              <img :src="imgList.icon[0]" alt="" />
            </div>
            <div class="text">郑州-高新区-莲花街316号-6号楼-207厂房</div>
          </div>
        </div>
        <div class="right">
          <!-- 二维码 -->
          <div class="box">
            <img :src="imgList.icon[3]" alt="" />
            <div class="title_box">
              <div class="title">微信好友</div>
              <div class="title">关注我们</div>
              <div class="title">官方抖音</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box_02_02">
        <a class="a" href="https://beian.miit.gov.cn">
          郑州中昆农业技术开发有限公司 豫ICP备12010654号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import imgList from "@/myJs/imgList";
export default {
  name: "ContactInformation",
  setup() {
    return {
      imgList,
    };
  },
};
</script>

<style lang="less" scoped>
.bottom {
  padding: 0;
  .box_02 {
    margin: 0;
    width: 100%;
    background-color: rgb(51, 51, 51);
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    .box_02_01 {
      height: 300px;
      width: 70%;
      display: flex;
      align-items: center;

      .left {
        flex: 1;
        text-align: left;
        .title {
          font-size: 26px;
          text-align: left;
          padding: 0 0 10px 0;
        }
        .item {
          display: flex;
          padding: 5px 0;
          font-size: 16px;
          .icon {
            width: 20px;
            height: 20px;
            margin: 0 10px 0 0;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
      .right {
        flex: 1;
        // border: 1px solid rgb(110, 4, 231);
        display: flex;
        justify-content: right;

        .box {
          width: 400px;
          // border: 1px solid red;
          img {
            width: 400px;
            object-fit: contain;
          }
          .title_box {
            width: 100%;
            display: flex;
            justify-content: space-around;
            // border: 1px solid red;
          }
        }
      }
    }
    .box_02_02 {
      height: 50px;
      width: 70%;
      border-top: 1px solid rgb(255, 255, 255);
      padding: 10px;
      .a {
        color: white;
        text-decoration: none;
      }
    }
  }
}
</style>
