<template>
  <div class="threeImgBox">
    <div
      class="threeImg"
      @mousedown="start()"
      @mousemove="handleMouseMove($event)"
      @mouseup="end()"
      @touchstart="start()"
      @touchmove="handleTouchMove($event)"
      @touchend="end()"
    >
      <!-- @mousewheel.prevent="rollImg" -->

      <img
        :src="item.base64"
        alt=""
        v-for="(item, index) in list"
        :key="index"
        :ref="getRef_01"
        v-show="item.name == data.showImgName"
      />
    </div>

    <!-- <div>{{ item }}</div> -->
  </div>
  <!-- 进度条 -->
  <!-- <div class="barBox" ref="speedBar">
    <div class="bar">
      <div class="txt">{{ data.speed }}</div>
    </div>
  </div> -->
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import api from "@/api/index";
import axios from "axios";
import { useRoute } from "vue-router";
import JSzip from "jszip";
import JSZipUtils from "jszip-utils";
let zip = new JSzip();
let speedBar = ref(null);
let ref_01 = ref([]);

// 压缩包中的图片数组
const image = ref(null);
let route = new useRoute();
let path =
  "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/netdisk/" +
  route.query.value3;

//   压缩包中的图片数量
// 总图片数量
let numberX = route.query.value1;
// 纵轴图片数量
let numberY = route.query.value2;
// 总图片数 =
let imgNumber = numberX * numberY;
// 步进距离
let stepX = Number(route.query.value4);
let stepY = Number(route.query.value5);

let data = reactive({
  showImgName: "5_0.jpg", // 显示的图片名
  speed: "加载中……",
  // 图片下标
  index: 0,
  zipName: route.query.value2,
  //   number: 3,
  imgNumber: 0,
  imgPath: "",
  timer_debounce: null,
  drag: false,
  x: "",
  y: "",
  dirX: "0",
  dirY: "0",
  openMouse: "0",
  direction: {
    x: "",
    y: "",
  },
  count: 0,
  count2: 0,
  topDown: 0,
  indexTop: 0,
});
const getRef_01 = (el) => {
  if (el) {
    ref_01.value.push(el);
  }
};
const start = () => {
  data.drag = true;
};
const end = () => {
  data.drag = false;
};
let img = reactive({
  x: 0,
  y: 0,
});
let imgPosition = reactive({
  x: 0,
  y: 0,
});

// 图片列表
let list = ref([]);

onMounted(() => {
  // 获取图片列表
  setTimeout(() => {
    getList();
  }, 500);
});

// 获取压缩包中的图片
const getList = () => {
  JSZipUtils.getBinaryContent(path, function (err, data) {
    if (err) throw err;
    // 解析Zip文件
    // for (let j = 0; i < 5; j++) {
    // }
    zip.loadAsync(data).then(function (zip) {
      // 读取Zip中的文件
      // 先循环y周，后循环x轴
      for (let i = 0; i < numberY; i++) {
        for (let j = 0; j < numberX; j++) {
          let imgName = i + "_" + j + ".jpg";
          zip
            .file(imgName)
            .async("base64")
            .then((res) => {
              let imgBase64 = "data:image/jpeg;base64, " + res;
              let a = {
                name: imgName,
                base64: imgBase64,
              };
              list.value.push(a);
            });
        }
      }
    });
  });
};

const moveImg = (e) => {
  let x = e.clientX;
  let y = e.clientY;
  let left = x - e.target.offsetLeft;
  let top = y - e.target.offsetTop;
  document.onmousemove = (ev) => {
    e.target.style.left = ev.clientX - left + "px";
    e.target.style.top = ev.clientY - top + "px";
  };
  document.onmouseup = () => {
    document.onmousemove = null;
  };
};

// 缩放图片
const rollImg = (e) => {
  for (let i = 0; i < 12; i++) {
    for (let j = 0; j < 39; j++) {
      let transform = ref_01.value[i][j].style.transform;
      let zoom =
        transform.indexOf("scale") != -1
          ? +transform.split("(")[1].split(")")[0]
          : 1;
      zoom += e.wheelDelta / 1200;
      if (zoom > 0.5 && zoom < 3) {
        ref_01.value[i].style.transform = "scale(" + zoom + ")";
        if (zoom < 1 || zoom == 1) {
          ref_01.value[i].style.marginTop = 0;
        } else if (zoom > 1 && zoom < 2) {
          ref_01.value[i].style.marginTop = zoom * 100 + "px";
        } else if (zoom >= 2 && zoom < 3) {
          ref_01.value[i].style.marginTop = zoom * 250 + "px";
        }
      }
    }
  }
};
const handleMouseMove = (event) => {
  if (data.drag == true) {
    // 判断鼠标方向 左右
    if (data.dirX < event.offsetX) {
      data.dirX = event.offsetX;
      data.direction.x = "右";
      data.count = data.count - stepX;
    } else {
      data.dirX = event.offsetX;
      data.direction.x = "左";
      data.count = data.count + stepX;
    }
    // 判断鼠标方向 上下
    if (data.dirY < event.offsetY) {
      data.dirY = event.offsetY;
      data.direction.y = "下";
      data.topDown = data.topDown + stepY;
    } else {
      data.dirY = event.offsetY;
      data.direction.y = "上";
      data.topDown = data.topDown - stepY;
    }

    // 左右限位 首位循环
    if (data.count < 0) {
      data.count = numberX - 1;
    }
    if (data.count > numberX - 1) {
      data.count = 0;
    }
    // 上下限位
    if (data.topDown < 0) {
      data.topDown = 0;
    }
    if (data.topDown > numberY) {
      data.topDown = numberY - 1;
    }
    // 取整
    // x轴
    data.index = Number.parseInt(data.count);
    // y轴
    data.indexTop = Number.parseInt(data.topDown);
    // 拼接应该显示的图片名
    data.showImgName = data.indexTop + "_" + data.index + ".jpg";
  }
};
const handleTouchMove = (event) => {
  if (data.drag == true) {
    // 判断鼠标方向 左右
    if (data.dirX < event.targetTouches[0].pageX) {
      data.dirX = event.targetTouches[0].pageX;
      data.direction.x = "右";
      data.count = data.count - stepX;
    } else {
      data.dirX = event.targetTouches[0].pageX;
      data.direction.x = "左";
      data.count = data.count + stepX;
    }
    // 判断鼠标方向 上下
    if (data.dirY < event.targetTouches[0].pageY) {
      data.dirY = event.targetTouches[0].pageY;
      data.direction.y = "下";
      data.topDown = data.topDown + stepY;
    } else {
      data.dirY = event.targetTouches[0].pageY;
      data.direction.y = "上";
      data.topDown = data.topDown - stepY;
    }

    // 左右限位 首位循环
    if (data.count < 0) {
      data.count = numberX - 1;
    }
    if (data.count > numberX - 1) {
      data.count = 0;
    }
    // 上下限位
    if (data.topDown < 0) {
      data.topDown = 0;
    }
    if (data.topDown > numberY) {
      data.topDown = numberY - 1;
    }
    // 取整
    // x轴
    data.index = Number.parseInt(data.count);
    // y轴
    data.indexTop = Number.parseInt(data.topDown);
    // 拼接应该显示的图片名
    data.showImgName = data.indexTop + "_" + data.index + ".jpg";
  }
};
</script>

<style lang="less" scoped>
.barBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  .bar {
    width: 600px;
    height: 200px;
    background-color: white;
    border-radius: 10px;
    border: 2px solid rgb(200, 200, 200);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text {
      font-size: 30px;
      font-weight: bold;
    }
  }
}
.threeImgBox {
  // width: 100%;
  // height: 100%;
  width: 100%;
  //   height: 90%;
  // height: 800px;
  // position: fixed;
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //   border: 1px solid red;
  @media only screen and (max-width: 800px) {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    min-height: 100vh;
    overflow: hidden;
  }

  .threeImg {
    width: 60%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: hidden;
    @media only screen and (max-width: 800px) {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: black;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      -webkit-user-drag: none;

      -moz-user-drag: none;

      -ms-user-drag: none;

      //   user-drag: none;
      //   border: 1px solid red;
    }
  }
}
</style>
