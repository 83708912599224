<template>
  <div class="download">
    <div class="inputBox">
      <input type="text" placeholder="请输入提取码" v-model="data.input" />
      <div class="btn" @click="submit()">提交</div>
    </div>
    <div class="right" v-show="data.data.length > 0">
      <table>
        <tr>
          <th>图片</th>

          <th>ID</th>
          <th>文件名</th>
          <th>文件格式</th>
          <th>标签</th>
          <th>下载</th>
        </tr>
        <tr v-for="(item, index) in data.data" :key="index">
          <td class="imgBox" @click="window('open', item.para_10)">
            <img :src="getUrl(item.para_09)" alt="" />
          </td>

          <td>{{ item.id }}</td>
          <td>{{ item.para_02 }}</td>
          <td>{{ item.para_03.split(".")[1] }}</td>
          <td>{{ item.para_04 }}</td>
          <td class="download" @click="download(item.para_03)">下载</td>
        </tr>
      </table>
    </div>
  </div>

  <div class="pupWindow">
    <div class="box">
      <img
        :src="
          'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
          data.src
        "
        alt=""
        @click="window('close')"
      />
    </div>
  </div>
</template>

<script setup>
import api from "@/api/index";
import axios from "axios";
import { onMounted, reactive } from "vue";
import COS from "@/myJs/cos-js-sdk-v5.min.js";
import getUrl from "@/myJs/getUrl";
const cos = new COS({
  SecretId: "AKIDSmULHAA5300pePWZfmmaGwklcGix0vhN", // 推荐使用环境变量获取；用户的 SecretId，建议使用子账号密钥，授权遵循最小权限指引，降低使用风险。子账号密钥获取可参考https://cloud.tencent.com/document/product/598/37140
  SecretKey: "bF5taM8qKacQ1VmgS8iCkCRXbcZbltqs", // 推荐使用环境变量获取；用户的 SecretKey，建议使用子账号密钥，授权遵循最小权限指引，降低使用风险。子账号密钥获取可参考https://cloud.tencent.com/document/product/598/37140
});
let data = reactive({
  input: "",
  data: {},
});

const submit = () => {
  let input = data.input.trim();
  if (input == "") {
    return alert("请输入提取码");
  }
  let data_01 = {
    command: "searchBy1and6",
    para_01: "网盘",
    para_06: input,
  };
  axios({
    method: "get",
    url: api.host + api.selectUniversal,
    params: data_01,
  }).then((res) => {
    data.data = res.data.results;
    if (data.data.length == 0) {
      alert("提取码错误");
    }
  });
};

// 下载文件
const download = (para_03) => {
  // 下载文件名
  let path = "netdisk/" + para_03;

  cos.getObjectUrl(
    {
      Bucket: "zhongkun-1306785868", // 填入您自己的存储桶，必须字段
      Region: "ap-shanghai", // 存储桶所在地域，例如 ap-beijing，必须字段
      Key: path, // 存储在桶里的对象键（例如1.jpg，a/b/test.txt），支持中文，必须字段
      Sign: true, // 获取带签名的对象 URL
    },
    function (err, data) {
      if (err) return console.log(err);
      /* url为对象访问 url */
      var url = data.Url;
      /* 复制 downloadUrl 的值到浏览器打开会自动触发下载 */
      var downloadUrl =
        url +
        (url.indexOf("?") > -1 ? "&" : "?") +
        "response-content-disposition=attachment";
      // 补充强制下载的参数并重命名下载后的文件

      // a标签下载
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = data.downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  );
};

// 弹窗
const window = (para, name) => {
  let window = document.querySelector(".pupWindow");
  if (para == "open") {
    window.style.display = "block";
    data.src = name;
  } else {
    window.style.display = "none";
  }
};
</script>

<style lang="less" scoped>
.pupWindow {
  display: none;
  .box {
    z-index: 99;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.right {
  width: 100%;
  padding: 0 20px 0 220px;
  table,
  tr,
  th,
  td {
    border: 1px solid black;
    text-align: center;
  }

  table {
    width: 100%;
    td {
      padding: 0 20px;
    }
    .download {
      font-weight: bold;
      color: skyblue;
    }
    .download:active {
      color: rgb(255, 177, 55);
    }
    .imgBox {
      width: 150px;
      height: 150px;
      padding: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.inputBox {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    border: 2px solid gray;
    border-radius: 5px;
    padding: 10px 0;
  }
  .btn {
    // height: 100%;
    padding: 10px 20px;
    background-color: rgb(255, 177, 55);
    border-radius: 5px;
  }
}
</style>
