<template>
  <div class="home">
    <div class="imgBox">
      <img :src="data.topImg" alt="" @click="GoOtherPage(data.topIndex)" />
      <div class="miniImgBox">
        <div
          :class="{
            circleActive: data.topIndex == 0,
            circle: data.topIndex != 0,
          }"
          @click="ChangeTopImg(0)"
        ></div>
        <div
          class="circle"
          :class="{
            circleActive: data.topIndex == 1,
            circle: data.topIndex != 1,
          }"
          key=""
          @click="ChangeTopImg(1)"
        ></div>
        <div
          class="circle"
          :class="{
            circleActive: data.topIndex == 2,
            circle: data.topIndex != 2,
          }"
          key=""
          @click="ChangeTopImg(2)"
        ></div>
        <div
          class="circle"
          :class="{
            circleActive: data.topIndex == 3,
            circle: data.topIndex != 3,
          }"
          key=""
          @click="ChangeTopImg(3)"
        ></div>
        <div
          class="circle"
          :class="{
            circleActive: data.topIndex == 4,
            circle: data.topIndex != 4,
          }"
          key=""
          @click="ChangeTopImg(4)"
        ></div>
      </div>
    </div>
    <div class="middle">
      <div class="item">提供高效优质的项目解决服务</div>
      <div class="img_box">
        <img :src="imgList.list_home[0]" alt="" />
        <img :src="imgList.list_home[1]" alt="" />
        <img :src="imgList.list_home[2]" alt="" />
        <img :src="imgList.list_home[3]" alt="" />
        <img :src="imgList.list_home[4]" alt="" />
      </div>
      <div class="title_box">
        <div class="box" @click="GoOtherPage(1)">
          <div class="box_01"></div>
          <div class="box_02">昆虫标本</div>
        </div>
        <div class="box" @click="GoOtherPage(0)">
          <div class="box_01"></div>
          <div class="box_02">展馆规划</div>
        </div>
        <div class="box" @click="GoOtherPage(3)">
          <div class="box_01"></div>
          <div class="box_02">昆虫繁育</div>
        </div>
        <div class="box" @click="GoOtherPage(2)">
          <div class="box_01"></div>
          <div class="box_02">科普研学</div>
        </div>
        <div class="box" @click="GoOtherPage(5)">
          <div class="box_01"></div>
          <div class="box_02">展品礼品定制</div>
        </div>
      </div>
    </div>
    <ContactInformation />
  </div>
  <div class="mobile_con">
    <img :src="imgList.list_06[0]" alt="" @click="GoOtherPage(data.topIndex)" />
    <img :src="imgList.list_06[1]" alt="" @click="GoOtherPage(data.topIndex)" />
    <img :src="imgList.list_06[2]" alt="" @click="GoOtherPage(data.topIndex)" />
    <img :src="imgList.list_06[3]" alt="" @click="GoOtherPage(data.topIndex)" />
    <img :src="imgList.list_06[4]" alt="" @click="GoOtherPage(data.topIndex)" />

    <div class="box_01">提供高效优质的项目解决服务</div>
    <div class="box_02">
      <img :src="imgList.list_home[0]" alt="" />
      <img :src="imgList.list_home[1]" alt="" />
      <img :src="imgList.list_home[2]" alt="" />
      <img :src="imgList.list_home[3]" alt="" />
      <img :src="imgList.list_home[4]" alt="" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import ContactInformation from "./ContactInformation.vue";
import ContactMobile from "./ContactMobile.vue";
import imgList from "@/myJs/imgList";
import { useRouter } from "vue-router";
export default {
  components: {
    ContactInformation,
    ContactMobile,
  },
  setup() {
    const router = new useRouter();
    let data = reactive({
      topImg: imgList.list_06[0], //顶部图
      topIndex: 0, //顶部图下标

      imgList_02: {},
      imgList_03: {}, //底部轮播图
    });



    //从后端获取图片数据
    const GetImgData = () => {

    }

    // 更改顶部轮播图
    const ChangeTopImg = (index) => {
      data.topIndex = index;
      data.topImg = imgList.list_06[index];
    };

    // 跳转页面
    const GoOtherPage = (para) => {
      let name;
      if (para == 0) {
        name = "MuseumDesign";
      } else if (para == 1) {
        name = "InsectSpecimen";
      } else if (para == 2) {
        name = "ScienceResearch";
      } else if (para == 3) {
        name = "InsectBreed";
      } else if (para == 4) {
        name = "InsectSpecimen";
      } else if (para == 5) {
        name = "InsectCraft";
      }
      router.push({
        name: name,
      });
    };

    return {
      GetImgData,
      GoOtherPage,
      ChangeTopImg,
      data,
      imgList,
      ContactInformation,
      ContactMobile,
    };
  },
};
</script>
<style lang="less" scoped>
.mobile_con {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0 10vh 0;

  @media only screen and (min-width: 800px) {
    display: none;
  }
  img {
    width: 100%;
  }
  .box_01 {
    padding: 3vh 0;
    font-size: 1.5rem;
    color: gray;
    font-weight: bold;
  }
  .box_02 {
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    img {
      height: 20vh;
      object-fit: contain;
    }
  }
}
.home {
  @media only screen and (max-width: 800px) {
    display: none;
  }
  .imgBox {
    width: 100%;
    position: relative;
    .miniImgBox {
      width: 100%;
      height: 30px;
      object-fit: cover;
      position: absolute;
      bottom: 20px;
      left: 0;
      // border: 1px solid red;
      display: flex;
      justify-content: center;
      // z-index: 80;
      .circle {
        width: 20px;
        height: 20px;
        border: 1px solid white;
        border-radius: 10px;
        margin: 0 5px;
        z-index: 80;
      }
      .circleActive {
        width: 20px;
        height: 20px;
        border: 1px solid white;
        border-radius: 10px;
        margin: 0 5px;
        background-color: white;
      }
    }
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  .middle {
    width: 100%;
    color: gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // border: 1px solid red;
    .title_box {
      position: absolute;
      padding: 165px 0 0 0;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      z-index: 99;
      display: flex;
      justify-content: center;
      .box {
        width: 250px;
        height: 100%;
        .box_01 {
          height: 80%;
        }
        .box_02 {
          height: 20%;
          background-color: white;
          opacity: 0.7;
          font-size: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
        }
      }
      .box .box_02:hover {
        opacity: 1;
        color: black;
      }

      .title {
        // border: 1px solid red;
        width: 250px;
        // height: 80px;
        background-color: white;
        opacity: 0.8;
        font-size: 30px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .item {
      font-size: 50px;
      padding: 50px 0;
    }

    .img_box {
      display: flex;
      width: 100%;
      justify-content: center;

      img {
        width: 250px;
        object-fit: contain;
      }
    }
  }
}
</style>
