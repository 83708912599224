<template>
  <div class="cer_mobile">
    <img :src="getBac()" alt="" />

    <div class="chineseName">纳西索斯闪蝶</div>
    <div class="latinName">Morpho helenor narcissus</div>
    <div class="classficition">
      <div class="order">鳞翅目</div>
      <div class="family">闪蝶科</div>
    </div>
    <div class="number">
      <div class="title">藏品编号：</div>
      <div>{{ data.data.para_02 }}</div>
    </div>
    <div class="intro">{{ data.intro }}</div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import api from "@/api/index";
import axios from "axios";
import { onMounted, reactive } from "vue";

let route = new useRoute();
let id = route.query.id;
let data = reactive({
  data: {},
  intro:
    "海伦纳西索斯闪蝶的拉丁名源于希腊神话中最美丽的女子--海伦Helen，是世界上公认的最漂亮的蝴蝶之一，以其大型华丽，色彩鲜艳闻名。海伦闪蝶翅上的复杂结构在光学作用下产生了金属般的蓝色光泽。2022年，海伦纳西索斯闪蝶通过国家法案，成为哥斯达黎加的18个国家象征之一。",
});
onMounted(() => {
  getData();
  // CollectionCertificate
});
const getData = () => {
  let data_01 = {
    id: id,
    command: "selectById",
    para_01: "收藏证书",
  };

  axios
    .get(api.host + api.selectUniversal, {
      params: data_01,
    })
    .then((res) => {
      data.data = res.data.results[0];
    });
};

const getBac = () => {
  return "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/1717036564587.webp";
};
</script>

<style lang="less" scoped>
.cer_mobile {
  display: none;
  @media only screen and (max-width: 1000px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 99;
    padding: 1vh 3vw;
    font-size: 2rem;
  }
  img {
    width: 100%;
    object-fit: contain;
  }

  .chineseName {
    font-weight: bold;
    margin: 1vh 0 0 0;
    font-size: 2.5rem;
  }
  .latinName {
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    margin: 0.5vh 0;
  }
  .classficition {
    display: flex;
    .order {
      margin: 0 5vw 0 0;
    }
  }
  .number {
    width: 100vw;
    display: flex;
  }
  .intro {
    margin: 2vh;
  }
}
</style>
