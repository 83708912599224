<template>
  <div class="pc">
    <div class="museumBox">
      <div class="imgBox">
        <img :src="data.src" alt="" />
      </div>
      <div class="miniImgbox">
        <img :src="imgList.list_09[0]" alt="" @click="ChangeImg(0)" />
        <img :src="imgList.list_09[1]" alt="" @click="ChangeImg(1)" />
        <img :src="imgList.list_09[2]" alt="" @click="ChangeImg(2)" />
        <img :src="imgList.list_09[3]" alt="" @click="ChangeImg(3)" />
      </div>
    </div>
    <ContactInformation />
  </div>
  <!-- 手机版 -->
  <div class="mobile_con">
    <div class="title">昆虫馆</div>
    <img :src="imgList.list_09[0]" alt="" />
    <img :src="imgList.list_09[1]" alt="" />
    <img :src="imgList.list_09[2]" alt="" />
    <img :src="imgList.list_09[3]" alt="" />
  </div>
</template>

<script>
import imgList from "@/myJs/imgList";
import ContactInformation from "./ContactInformation.vue";
import { reactive } from "vue";

export default {
  components: {
    ContactInformation,
  },

  setup() {
    let data = reactive({
      src: imgList.list_09[0],
    });

    const ChangeImg = (index) => {
      data.src = imgList.list_09[index];
    };
    return {
      ContactInformation,
      data,
      imgList,
      ChangeImg,
    };
  },
};
</script>

<style lang="less" scoped>
.mobile_con {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0 10vh 0;
  padding: 0;
  @media only screen and (min-width: 800px) {
    display: none;
  }
  .title {
    font-size: 1.6rem;
    font-weight: bold;
    padding: 2vh;
    color: gray;
  }

  img {
    width: 100vw;
    object-fit: contain;
  }
}
.pc {
  @media only screen and (max-width: 800px) {
    display: none;
  }
}
.museumBox {
  width: 100%;
  // height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .miniImgbox {
    width: 100%;
    height: 150px;
    // border: 1px solid red;
    margin: 0 0 10px 0;
    display: flex;
    justify-content: center;
    img {
      height: 150px;
      width: auto;
    }
  }
  .imgBox {
    width: 100%;
    height: 485px;
    // border: 1px solid red;
    margin: 10px 0;
    img {
      width: auto;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
