<template>
  <div>
    <div class="con">
      <div class="title">
        <select
          name=""
          id=""
          v-model="data.InsectList"
          @click="GetList(data.InsectList)"
        >
          <option value="中华人民共和国进境植物检疫性有害生物名录">
            中华人民共和国进境植物检疫性有害生物名录_2021
          </option>
          <option value="中国自然生态系统外来入侵物种名单">
            中国自然生态系统外来入侵物种名单
          </option>
          <option value="国家重点保护野生动物名录">
            国家重点保护野生动物名录_2021
          </option>
          <option value="三有名录">三有名录_2023</option>
          <option value="CITES_附录I">CITES_附录I</option>
          <option value="CITES_附录II">CITES_附录II</option>
          <option value="CITES_附录III">CITES_附录III</option>
        </select>
        <input
          type="text"
          v-model="data.input"
          placeholder="请输入目、科、中文名、拉丁名"
        />

        <div class="searchBar">{{ filter.length }}</div>
      </div>
      <div class="imgShell" v-for="(item, index) in filter" :key="index">
        <div class="imageBox" @click="mainBox('open', item.para_10)">
          <img
            :src="
              'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
              item.para_09
            "
            alt=""
            class="image"
          />
        </div>
        <div class="font_bold">{{ item.para_04 }}</div>
        <div class="latin">{{ item.para_05 }}</div>
        <div class="order_family">{{ item.para_02 + " " + item.para_03 }}</div>
      </div>
    </div>
    <!-- 大图 -->
    <div class="mainImgWindow">
      <div class="mainBox" @click="mainBox('close')">
        <img
          class="mainImg"
          :src="
            'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
            data.mainSrc
          "
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/index";
import axios from "axios";
import { computed, onMounted, reactive } from "vue";
export default {
  setup() {
    let data = reactive({
      data: {},
      type: {},
      select: "",
      mainSrc: "", // 大图
      input: "",
      filter_input: "", //过滤器
      page: 1,
      InsectList: "中华人民共和国进境植物检疫性有害生物名录", //名录
    });
    let dataToSql = reactive({
      command: "",
      id: null,
      para_01: "",
      para_02: "",
      para_03: "",
      para_04: "",
      para_05: "",
      para_06: "",
      para_07: "",
      para_08: "",
      para_09: "",
      para_10: "",
      para_11: "",
      para_12: "",
      para_13: "",
      para_14: "",
      para_15: "",
      page: 1,
      input: "",
    });
    onMounted(() => {
      GetList("中华人民共和国进境植物检疫性有害生物名录");
    });

    const GetList = (list) => {
      dataToSql.para_01 = "昆虫查询系统";
      dataToSql.para_14 = list;
      getData("selectByPara1and14");
    };

    // 打开、关闭大图
    const mainBox = (order, mainSrc) => {
      let window = document.querySelector(".mainImgWindow");
      let mainBox = document.querySelector(".mainBox");
      let mainImg = document.querySelector(".mainImg");
      if (order == "open") {
        window.style.display = "block";
        data.mainSrc = mainSrc;
        mainBox.style.width = mainImg.width;
      } else if (order == "close") {
        window.style.display = "none";
        data.mainSrc = "";
      }
    };

    // 获取全部数据
    const getData = (command) => {
      dataToSql.command = command;
      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        data.data = res.data.results;
      });
    };

    // 初始化数据
    const initData = () => {
      data.input = "";
      dataToSql.page = 1;
      getData("selectByPara_01");
    };

    const filter = computed(() => {
      let item = [];
      let input = data.input.trim();
      for (let k in data.data) {
        if (input == "") {
          item.push(data.data[k]);
        } else if (data.data[k].para_02.includes(input)) {
          item.push(data.data[k]);
        } else if (data.data[k].para_03.includes(input)) {
          item.push(data.data[k]);
        } else if (data.data[k].para_04.includes(input)) {
          item.push(data.data[k]);
        } else if (data.data[k].para_05.toLowerCase().includes(input)) {
          item.push(data.data[k]);
        }
      }

      return item;
    });

    return {
      initData,
      data,
      getData,
      mainBox,
      GetList,
      filter,
    };
  },
};
</script>

<style lang="less" scoped>
.font_bold {
  font-weight: bold;
}
.latin {
  font-style: italic;
  margin: 5px 0;
  width: 100%;
  text-align: center;
}
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mainImgWindow {
  display: none;
  .mainBox {
    display: flex;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
}
.clear {
  padding: 10px 20px;
  border: 2px solid gray;
  border-radius: 5px;
  margin: 0 0 0 10px;
  font-size: 18px;
  background-color: rgb(241, 241, 241);
}
.select {
  padding: 10px 20px;
  border: 2px solid gray;
  border-radius: 5px;
  margin: 0 0 0 20px;
  font-size: 18px;
  background-color: rgb(241, 241, 241);
}
.input_box {
  // position: fixed;
  top: 0;
  padding: 0 0 20px 0;
  width: 100%;
  background-color: white;
  display: flex;
  margin: 0 0 20px 0;
}
input {
  width: 30%;
  padding: 10px;
  border: 2px solid gray;
  border-radius: 5px;
  margin: 0 0 0 10px;
  background-color: rgb(241, 241, 241);
  text-align: left;
}
.con {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 70px 0 0 0;
  @media only screen and (max-width: 500px) {
    padding: 7vh 0 0 0;
  }

  .title {
    text-align: center;
    padding: 20px 0 10px 0;
    position: fixed;
    background-color: rgb(255, 255, 255);
    top: 70px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 500px) {
      flex-wrap: wrap;
      justify-content: space-around;
      //   border: 1px solid red;
      height: 15vh;
    }

    select {
      padding: 5px 10px;
      border: 2px solid gray;
      border-radius: 5px;
      @media only screen and (max-width: 500px) {
        width: 90vw;
        margin: 0 0 1vh 0;
      }
    }
    input {
      width: 200px;
      padding: 5px 5px;
      @media only screen and (max-width: 500px) {
        width: 40vw;
      }
    }
    .searchBar {
      font-size: 20px;
      margin: 0;
      padding: 5px 15px;
      border-radius: 5px;
      @media only screen and (max-width: 500px) {
        font-size: 15px;
        width: 10vw;
      }
    }
  }
}
.imgShell {
  width: 300px;
  // height: 300px;
  // border: 1px solid rgb(255, 120, 120);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px 50px 10px;
  box-shadow: 0 0 2px 2px rgb(200, 200, 200);
  padding: 20px;
  border-radius: 10px;

  @media only screen and (max-width: 1000px) {
    width: 40vw;
    box-shadow: none;
    // height: 50vw;
  }
  .order_family {
    font-size: 15px;
  }
}
.imgShell:hover {
  background-color: rgb(240, 240, 240);
}
.imageBox {
  width: 220px;
  height: 220px;
  // border: 1px solid black;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  @media only screen and (max-width: 1000px) {
    width: 38vw;
    height: 38vw;
  }
}
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
