<template>
  <div>
    <div class="right">
      <div class="item" v-for="(item, index) in data.data" :key="index">
        <div class="img_box" @click="popWindow('open', item.para_10)">
          <img
            class="mainImg"
            :src="
              'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
              item.para_09
            "
            alt=""
          />
        </div>
        <div class="photo_id">{{ item.id }}</div>
      </div>
    </div>
    <div class="pc">
      <div class="left">
        <div
          :class="{
            item: data.value != '中昆图片_展品',
            item_active: data.value == '中昆图片_展品',
          }"
          @click="getData('中昆图片_展品')"
        >
          展品
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_原木框',
            item_active: data.value == '中昆图片_原木框',
          }"
          @click="getData('中昆图片_原木框')"
        >
          装框标本-原木框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_胡桃木框',
            item_active: data.value == '中昆图片_胡桃木框',
          }"
          @click="getData('中昆图片_胡桃木框')"
        >
          装框标本-胡桃木框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_黑框',
            item_active: data.value == '中昆图片_黑框',
          }"
          @click="getData('中昆图片_黑框')"
        >
          装框标本-黑框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_白框',
            item_active: data.value == '中昆图片_白框',
          }"
          @click="getData('中昆图片_白框')"
        >
          装框标本-白框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_棕框',
            item_active: data.value == '中昆图片_棕框',
          }"
          @click="getData('中昆图片_棕框')"
        >
          装框标本-棕框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_塑料框',
            item_active: data.value == '中昆图片_塑料框',
          }"
          @click="getData('中昆图片_塑料框')"
        >
          装框标本-塑料框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_生活史',
            item_active: data.value == '中昆图片_生活史',
          }"
          @click="getData('中昆图片_生活史')"
        >
          装框标本-生活史
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_分解标本',
            item_active: data.value == '中昆图片_分解标本',
          }"
          @click="getData('中昆图片_分解标本')"
        >
          装框标本-分解标本
        </div>
      </div>
    </div>

    <!-- 手机版 -->
    <div class="mobile">
      <div class="left">
        <div
          :class="{
            item: data.value != '中昆图片_展品',
            item_active: data.value == '中昆图片_展品',
          }"
          @click="getData('中昆图片_展品')"
        >
          展品
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_原木框',
            item_active: data.value == '中昆图片_原木框',
          }"
          @click="getData('中昆图片_原木框')"
        >
          原木框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_胡桃木框',
            item_active: data.value == '中昆图片_胡桃木框',
          }"
          @click="getData('中昆图片_胡桃木框')"
        >
          胡桃木框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_黑框',
            item_active: data.value == '中昆图片_黑框',
          }"
          @click="getData('中昆图片_黑框')"
        >
          黑色框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_白框',
            item_active: data.value == '中昆图片_白框',
          }"
          @click="getData('中昆图片_白框')"
        >
          白色框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_棕框',
            item_active: data.value == '中昆图片_棕框',
          }"
          @click="getData('中昆图片_棕框')"
        >
          棕色框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_塑料框',
            item_active: data.value == '中昆图片_塑料框',
          }"
          @click="getData('中昆图片_塑料框')"
        >
          塑料框
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_生活史',
            item_active: data.value == '中昆图片_生活史',
          }"
          @click="getData('中昆图片_生活史')"
        >
          生活史
        </div>
        <div
          :class="{
            item: data.value != '中昆图片_分解标本',
            item_active: data.value == '中昆图片_分解标本',
          }"
          @click="getData('中昆图片_分解标本')"
        >
          分解标本
        </div>
      </div>
    </div>

    <!-- 大图 -->
    <div class="popWindow" id="pop">
      <div class="mainBox" @click="popWindow('close')">
        <img
          class="mainImg"
          :src="
            'https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/' +
            data.mainSrc
          "
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import api from "@/api/index";
import axios from "axios";
export default {
  setup() {
    let data = reactive({
      data: {},
      item_value: "中昆图片_展品", //用于判断样式
      mainSrc: "", //大图
    });
    let dataToSql = reactive({
      para_01: "",
      para_02: "",
      para_03: "",
      para_04: "",
      para_05: "",
      para_06: "",
      para_07: "",
      para_08: "",
      input: "",
      command: "selectByPara_01Num",
    });

    onMounted(() => {
      getData("中昆图片_展品");
    });

    const getData = (value) => {
      data.value = value;
      dataToSql.para_01 = value;

      axios({
        method: "get",
        url: api.host + api.selectUniversal,
        params: dataToSql,
      }).then((res) => {
        data.data = res.data.results;
      });
    };

    // 打开、关闭大图
    const mainBox = (order, mainSrc) => {
      console.log(order, mainSrc);
      let window = document.querySelector(".popWindow");
      if (order == "open") {
        window.style.display = "block";
        data.mainSrc = mainSrc;
      } else if (order == "close") {
        window.style.display = "none";
        data.mainSrc = "";
      }
    };

    const popWindow = (isOpen, src) => {
      let box = document.querySelector("#pop");
      console.log(box.style.display);
      if (isOpen == "open") {
        box.style.display = "block";
        data.mainSrc = src;
      } else if (isOpen == "close") {
        box.style.display = "none";
        data.mainSrc = "";
      }
    };

    return {
      data,
      dataToSql,
      getData,
      mainBox,
      popWindow,
    };
  },
};
</script>

<style lang="less" scoped>
.popWindow {
  display: none;
  .mainBox {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    justify-content: center;
    padding: 50px;
    @media only screen and (max-width: 800px) {
      padding: 5vw;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.right {
  padding: 20px 0 0 220px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media only screen and (max-width: 800px) {
    padding: 0 0 0 26vw;
  }
  .item {
    width: 250px;
    height: 280px;
    border: 2px solid rgb(181, 181, 181);
    border-radius: 5px;
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 0 0;
    background-color: rgb(240, 240, 240);
    @media only screen and (max-width: 800px) {
      width: 30vw;
      height: 35vw;
      border-radius: 5px;
      margin: 0 0 1vh 0;
      border: none;
      background-color: white;
      // border: 1px solid red;
    }
    .photo_id {
      padding: 10px 0 0 0;
      @media only screen and (max-width: 800px) {
        display: none;
      }
    }
    .img_box {
      width: 200px;
      height: 200px;
      border-radius: 5px;
      background-color: rgb(255, 255, 255);
      @media only screen and (max-width: 800px) {
        width: 30vw;
        height: 30vw;
        border: 2px solid rgb(220, 220, 220);
        border-radius: 5px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 5px;
        @media only screen and (max-width: 800px) {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 5px;
        }
      }
    }
    .img_box:hover {
      box-shadow: 0 0 5px 5px rgb(180, 180, 180);
      @media only screen and (max-width: 800px) {
        box-shadow: none;
      }
    }
  }
}

.pc {
  @media only screen and (max-width: 800px) {
    display: none;
  }

  .left {
    position: fixed;
    width: 200px;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(230, 230, 230);
    display: flex;
    flex-direction: column;
    padding: 80px 0 0 0;
    .item {
      text-align: left;
      color: rgb(57, 57, 57);
      width: 100%;
      padding: 8px 0 8px 20px;
    }
    .item_active {
      text-align: left;
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      width: 100%;
      padding: 8px 0 8px 20px;
    }
  }
}

.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0 10vh 0;
  @media only screen and (min-width: 800px) {
    display: none;
  }
  // .right {
  //   padding: 0 0 0 26vw;
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-around;
  //   .item {
  //     width: 30vw;
  //     height: 38vw;
  //     border-radius: 5px;
  //     margin: 0 0 1vh 0;
  //     .photo_id {
  //       width: 100%;
  //       text-align: center;
  //     }
  //     .img_box {
  //       width: 30vw;
  //       height: 30vw;
  //       border: 2px solid rgb(220, 220, 220);
  //       border-radius: 5px;
  //       img {
  //         width: 100%;
  //         height: 100%;
  //         object-fit: contain;
  //         border-radius: 5px;
  //       }
  //     }
  //   }
  // }
  .left {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 25vw;
    background-color: rgb(240, 240, 240);
    padding: 10vh 0 0 0;
    .item {
      // border: 1px solid red;
      width: 100%;
      padding: 1vh 0 1vh 3vw;
    }
    .item_active {
      // border: 1px solid red;
      width: 100%;
      padding: 1vh 0 1vh 3vw;
      background-color: white;
    }
  }
}
</style>
