<template>
  <div class="threeImgBox">
    <div
      class="threeImg"
      @mousedown="start()"
      @mousemove="handleMouseMove($event)"
      @mouseup="end()"
      @mousewheel.prevent="rollImg"
    >
      <img
        :src="item"
        alt=""
        v-for="(item, index) in data.imgList"
        :key="index"
        v-show="data.count == index"
        :ref="getRef_01"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import api from "@/api/index";
import axios from "axios";
import { useRoute } from "vue-router";

let ref_01 = ref([]);
const image = ref(null);
let route = new useRoute();
let data = reactive({
  value1: route.query.value1,
  value2: route.query.value2,
  imgNumber: 0,
  imgPath: "",
  timer_debounce: null,
  data: {},
  drag: false,
  src:
    "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/" +
    route.query.value3,
  x: "",
  y: "",
  dirX: "0",
  dirY: "0",
  openMouse: "0",
  direction: {
    x: "",
    y: "",
  },
  count: 0,
  count2: 0,
  topDown: 0,
  imgData: {},
  imgList: [],
  imgPath:
    "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/img/",
});
const getRef_01 = (el) => {
  if (el) {
    ref_01.value.push(el);
  }
};
const start = () => {
  data.drag = true;
};
const end = () => {
  data.drag = false;
};
let img = reactive({
  x: 0,
  y: 0,
});
let imgPosition = reactive({
  x: 0,
  y: 0,
});
let dataToSql = reactive({
  command: "searchNum",
  para_01: "中昆_图库",
  input: "",
});

onMounted(() => {
  // 获取图片列表
  getImgList();
});

const moveImg = (e) => {
  let x = e.clientX;
  let y = e.clientY;
  let left = x - e.target.offsetLeft;
  let top = y - e.target.offsetTop;
  document.onmousemove = (ev) => {
    e.target.style.left = ev.clientX - left + "px";
    e.target.style.top = ev.clientY - top + "px";
  };
  document.onmouseup = () => {
    document.onmousemove = null;
  };
};

// 缩放图片
const rollImg = (e) => {
  console.log("11");
  for (let i = 0; i < data.imgNumber; i++) {
    console.log("22");
    let transform = ref_01.value[i].style.transform;
    let zoom =
      transform.indexOf("scale") != -1
        ? +transform.split("(")[1].split(")")[0]
        : 1;
    zoom += e.wheelDelta / 1200;
    if (zoom > 0.5 && zoom < 3) {
      ref_01.value[i].style.transform = "scale(" + zoom + ")";
      if (zoom < 1 || zoom == 1) {
        ref_01.value[i].style.marginTop = 0;
      } else if (zoom > 1 && zoom < 2) {
        ref_01.value[i].style.marginTop = zoom * 100 + "px";
      } else if (zoom >= 2 && zoom < 3) {
        ref_01.value[i].style.marginTop = zoom * 250 + "px";
      }
    }
  }
};
const handleMouseMove = (event) => {
  if (data.drag == true) {
    // 判断鼠标方向 左右
    if (data.dirX < event.offsetX) {
      data.dirX = event.offsetX;
      data.direction.x = "右";
      data.count = data.count + 1;
    } else {
      data.dirX = event.offsetX;
      data.direction.x = "左";
      data.count = data.count - 1;
    }
    // 判断鼠标方向 上下
    if (data.dirY < event.offsetY) {
      data.dirY = event.offsetY;
      data.direction.y = "下";
      data.topDown = data.topDown + 1;
    } else {
      data.dirY = event.offsetY;
      data.direction.y = "上";
      data.topDown = data.topDown - 1;
    }

    // x限位 首位循环
    if (data.count < 0) {
      data.count = data.imgNumber - 1;
    }
    if (data.count > data.imgNumber - 1) {
      data.count = 0;
    }

    data.src = data.imgList[data.count];
  }
};

// 获取图片列表
const getImgList = () => {
  let data02 = {
    command: "searchNum",
    para_01: data.value2 + "_图库",
    input: data.value1,
  };

  axios({
    method: "get",
    url: api.host + api.selectUniversal,
    params: data02,
  }).then((res) => {
    // 初始图片

    data.imgData = res.data.results;
    data.imgNumber = res.data.results.length;

    for (let k in data.imgData) {
      let path = data.imgPath + data.imgData[k].para_10;
      data.imgList.push(path);
    }
  });
};
</script>

<style lang="less" scoped>
.threeImgBox {
  // width: 100%;
  // height: 100%;
  width: 100%;
  // height: 800px;
  // position: fixed;
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .threeImg {
    width: 60%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      -webkit-user-drag: none;

      -moz-user-drag: none;

      -ms-user-drag: none;

      user-drag: none;
    }
  }
}
</style>
