import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

let scene, camera, renderer;

const initScene = () => {
  console.log(111);
  scene = new THREE.Scene();
  camera = new THREE.Camera();
  renderer = new THREE.WebGLRenderer();

  let width = 1000;
  let height = 800;
  camera = new THREE.PerspectiveCamera(60, width / height, 0.1, 500);
  camera.position.set(30, 30, 30);
  camera.lookAt(0, 0, 0);

  renderer.setSize(width, height);
  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1.8;

  scene.add(camera);
};

const initControl = () => {
  let controls = new OrbitControls(camera, renderer.domElement);
  controls.enableZoom = true;
};

// 载入贴图
const loadHdr = (hdr) => {
  let path =
    "https://zhongkun-1306785868.cos-website.ap-shanghai.myqcloud.com/glb/" +
    hdr;

  let loader = new RGBELoader();
  loader.load(path, function (texture) {
    texture.mapping = THREE.EquirectangularReflectionMapping;
    scene.background = texture;
    scene.environment = texture;
  });
};

const render = () => {
  renderer.render(scene, camera);
  requestAnimationFrame(render);
};

initScene();
initControl();
loadHdr();
render();

export { renderer, loadHdr };
